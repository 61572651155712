import React, { useState } from 'react';
import axios from 'axios';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { updateComponentFields, updateDependentField } from '@redux/FormRedux/reducers/formSlice';

const isDependantField = field => ['make', 'model', 'year', 'trim'].includes(field);

const dependantOn = {
    model: 'make',
    year: 'model',
    trim: 'year'
};

const getApiUrl = (categoryId, field, dependentField) => {
    if (!isDependantField(field)) {
        return '';
    }

    if (field === 'make') {
        return `https://orangebookvalue.com/mmyt?category_id=${categoryId}`;
    }

    if (field === 'model' && dependentField['make']?.selected) {
        return `https://orangebookvalue.com/mmyt?category_id=${categoryId}&make=${dependentField['make'].selected}`;
    }

    if (field === 'year' && dependentField['make']?.selected && dependentField['model']?.selected) {
        return `https://orangebookvalue.com/mmyt?category_id=${categoryId}&make=${dependentField['make'].selected}&model=${dependentField['model'].selected}`;
    }

    if (
        field === 'trim' &&
        dependentField['make']?.selected &&
        dependentField['model']?.selected &&
        dependentField['year'].selected
    ) {
        return `https://orangebookvalue.com/mmyt?category_id=${categoryId}&make=${dependentField['make'].selected}&model=${dependentField['model'].selected}&year=${dependentField['year'].selected}`;
    }

    return '';
};

const Dropdown = ({ componentDetails, componentOptions }) => {
    const { id, label, is_required = 0, is_multiple = 0, title, options = [] } = componentOptions;

    const { dependentField } = useSelector(state => state.form);

    const { obvCategoryId } = useSelector(state => state.order);
    const {
        parameterId,
        parameterName,
        componentId,
        componentName = null,
        type = null,
        optionKey = null
    } = componentDetails;

    const [selected, setSelected] = useState('');
    const dispatch = useDispatch();

    const submitHandler = e => {
        e.preventDefault();

        setSelected(e.target.value);

        dispatch(
            updateComponentFields({
                parameterId,
                parameterName,
                componentId,
                optionId: id,
                name: componentName,
                title,
                type,
                value: e.target.value,
                optionKey
            })
        );

        if (isDependantField(componentName)) {
            dispatch(
                updateDependentField({
                    field: componentName,
                    selected: e.target.value
                })
            );
        }
    };

    React.useEffect(() => {
        const apiUrl = getApiUrl(obvCategoryId, componentName, dependentField);
        if (apiUrl) {
            axios.get(apiUrl).then(res => {
                if (res?.data?.data) {
                    dispatch(
                        updateDependentField({
                            field: componentName,
                            values: res?.data?.data
                        })
                    );
                }
            });
        }
    }, [obvCategoryId, componentName, dependentField?.[dependantOn?.[componentName]]?.selected]);

    const compOptions = dependentField?.[componentName]?.values || options;

    return (
        <div>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                as="select"
                aria-label={label}
                id={id}
                required={parseInt(is_required)}
                multiple={parseInt(is_multiple ? is_multiple : 0)}
                value={selected}
                onChange={submitHandler}
            >
                <option value="">{`Select ${componentName.replace(/_/g, ' ')}`}</option>
                {Object.keys(compOptions).map(key => {
                    return (
                        <option key={key} value={compOptions[key]}>
                            {compOptions[key]}
                        </option>
                    );
                })}
            </Form.Control>
        </div>
    );
};

export default Dropdown;
