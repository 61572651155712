import InnerPageBanner from '../InnerPageBanner';
import { useEffect } from "react";
import MetaTags from "./MetaTags";

const BikeInspection = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
        <MetaTags/>
            <InnerPageBanner heading={'Get A Used Bike Inspection With ECO'} bgImage={'bike-inspection-bg'} />
            <div className="service-section container-fluid mb-3 mt-3">
                <div className="row">
                    <div className="col-sm-12 mb-4 text-center">
                        <a href={process.env.REACT_APP_DROOM_WEBSITE_URL + '/inspection'} className="btn btn-primary px-4 py-2 text-capitalize">Book Inspection</a>
                    </div>
                    <div className="col-sm-7 col-md-7 offset-md-1">
                        <h2 className="fs-4 text-gray cp-medium">USED BIKE INSPECTION AND<br /> ITS IMPORTANCE</h2>
                        <div className="fs-5 mt-3 mb-2 text-gray">Why do you need a Motorcycle Mechanic?</div>
                        <p className="pn-regular fs-6">Do you own a bike, but you are not sure about its actual condition? Or, do you want to buy a pre-owned bike, but you do not know its overall health status? No matter which vehicle you own, it is very important to get it checked or inspected after a certain period of time. Used bike check up is necessary, and it is a good practice if bike inspection is done from time to time without any fail. This assures that the bike you are using stays in good health. For a proper bike inspection, a professional motorcycle mechanic is required.</p>
                    </div>
                    <div className="col-sm-5 col-md-4 pe-md-0">
                        <div className="d-block">
                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/imgusr/droomresponsive/img/content/bike-inspection-img.png"} alt="Bike inspection" className="w-100" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-5 col-md-3 offset-md-1">
                        <div className="d-block">
                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/imgusr/droomresponsive/img/content/bike-service.png"} alt="Bike inspection" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-sm-7 col-md-7">
                        <div className="fs-5 mt-3 mb-2 text-gray">Where to find a Motorcycle Mechanic near me?</div>
                        <p className="pn-regular fs-6">Bike inspection could be full of trouble and hassle to find a suitable bike mechanic near me when there is some doubt regarding the technician or the mechanic. Even a constant search for a bike mechanic shop near me may be too much hectic and frustrating. Also, there is another problem with taking the vehicle to a workshop to get it inspected. This is where ECO by Droom, India’s first and only marketplace for automobiles and vehicle related services, comes in. With the help of ECO, you can contact.</p>
                    </div>
                    <div className="col-sm-12 col-md-10 offset-md-1">
                        <div className="fs-5 mt-3 mb-2 text-gray">Why to Choose ECO?</div>
                        <p className="pn-regular fs-6">ECO is an online tool that provides the best inspection services for any vehicle. One more thing you should be careful about is the bike inspection cost. ECO inspection services by Droom provides used vehicle inspections at a very competitive cost. Droom ECO services include used car, used bike and used scooter inspection service. Moreover, you can book online bike mechanic and the inspection services will be carried out at your doorstep by a professional yet local bike mechanic without putting you in any kind of hassle. We understand that our users have a busy schedule and are busy in their daily life, so ECO conducts the used vehicle inspection services at the time mentioned by you as per your convenience.</p>
                    </div>
                    <div className="col-sm-12 col-md-10 offset-md-1">
                        <div className="fs-5 mt-3 mb-2 text-gray">What should a Bike Inspection Checklist Include?</div>
                        <p className="pn-regular fs-6">Not only your own bike, but even if you are planning to buy a used bike but you are not sure about its actual condition, you can book a bike inspection service from ECO. In fact, it is highly recommended that you get the used bike checked properly before buying it. Bike inspection services from ECO helps identify any kind of problem with your bike. The bike inspection checklist must include a detailed analysis of various parts of your motorcycle such as body of the vehicle, tires and wheels (both front and rear), L-lights & Electrics, Vehicle C-Control (both front and rear), vehicle stand, paint, damage caused by accident, rust, etc. The inspection will be carried on by an experienced technician who will see if any of the parts are malfunctioning or if there is any damage and give you a detailed report.</p>
                    </div>
                </div>
            </div>
        </>
    );
}
export default BikeInspection;