import React from 'react';
import { Button } from 'react-bootstrap';
const OrderButtons = ({ onAccept, onReject }) => {
    return (
        <div className="d-flex justify-content-between">
            <Button className="py-2 p-3" variant="outline-secondary" onClick={onReject}>
                Reject Order
            </Button>
            <Button className="py-2 p-3" variant="primary" onClick={onAccept}>
                Accept Order
            </Button>
        </div>
    );
};

export default OrderButtons;
