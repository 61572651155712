import React, { Fragment } from 'react';
import { ListGroup } from 'react-bootstrap';
import Parameter from '@pages/SelfInspection/AI/Parameter/Parameter';

const Screen = ({ parameters, guidelines }) => {
    return (
        <>
            {guidelines && guidelines.length > 0 && (
                <ListGroup as="ol" numbered bsPrefix="list-items">
                    <h2 className='text-center' style={{ marginTop: '15px' }}>Guidelines</h2>
                    {guidelines.map((guideline, index) => (
                        <ListGroup.Item as="li" key={index}>{guideline}</ListGroup.Item>
                    ))}
                </ListGroup>
            )}
            {parameters?.map((parameter, index) => {
                return (
                    <Fragment key={index}>
                        <Parameter key={parameter.id} components={parameter?.components} />
                    </Fragment>
                );
            })}
            <Parameter />
        </>
    );
};

export default Screen;
