import React from 'react';
import Heading from './Heading';
import OfferingTabs from './OfferingTabs';
import OfferingContent from './OfferingContent';
import WhyEcoContent from './WhyEcoContent';
import WhyEcoTab from './WhyEcoTabs';
import HowEcoWorks from './HowEcoWorks';
import Stats from './Stats';
import Testimonials from './Testimonials';
import EcoNews from './EcoNews';
import Faqs from './Faqs';
import Banner from './Banner';
import '../../../resources/css/home.css';
import CompareModal from './CompareModal';
import { useEffect } from "react";
import MetaTags from './MetaTags';

const Main = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    
    <>
      <MetaTags />
      <Banner />
      <Heading heading={'ECO offerings'} />
      <OfferingTabs />
      <OfferingContent />
      <Heading heading={'Why ECO?'} description={'An Independent, Objective, Unbiased, Comprehensive and Scientific Auto Inspection Service'} />
      <WhyEcoTab />
      <WhyEcoContent />
      <Stats />
      <HowEcoWorks />
      <Testimonials />
      <EcoNews />
      <Faqs />
      <CompareModal />
    </>
  );
}
export default Main;