import { useState, useEffect, useRef } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateComponentFields } from '@redux/FormRedux/reducers/formSlice';

const getAttributes = ({ input_type = 'text', text_caps = 0, ...rest }) => {
    let attrs = {};

    attrs.type = input_type;
    if (rest.input_format) {
        attrs.pattern = rest.input_format;
    }

    if (rest.max_length) {
        attrs.maxLength = rest.max_length;
    }

    if (rest.min_length) {
        attrs.minLength = rest.min_length;
    }

    if (rest.max_value) {
        attrs.max = rest.max_value;
    }

    if (rest.min_value) {
        attrs.min = rest.min_value;
    }

    if (input_type === 'numeric') {
        attrs.type = 'number';

        if (rest.max_length) {
            attrs.max = Math.pow(10, parseInt(rest.max_length) + 1) - 1;
        }

        if (rest.min_length) {
            attrs.min = parseInt(rest.min_length) === 0 ? 0 : Math.pow(10, parseInt(rest.min_length) - 1);
        }
    }

    return attrs;
};

const TextInput = ({ componentOptions, componentDetails }) => {
    const dispatch = useDispatch();

    const ref = useRef(null);

    const { id, label, placeholder, is_required = 0, text_caps = 0, ...rest } = componentOptions;

    const {
        parameterId,
        parameterName,
        componentId = null,
        componentName = null,
        title = null,
        type = null,
        optionKey = null
    } = componentDetails;

    const [compValue, setCompValue] = useState('');

    const [validationError, setValidationError] = useState('');

    const submitHandler = e => {
        setValidationError(e.target.validationMessage);
        setCompValue(e.target.value);
        dispatch(
            updateComponentFields({
                parameterId,
                parameterName,
                componentId,
                optionId: id,
                name: componentName,
                title,
                type,
                value: e.target.value,
                optionKey
            })
        );
    };

    useEffect(() => {
        setValidationError(ref.current.validationMessage);
    }, [ref]);

    return (
        <div>
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <FormControl
                    className={`${parseInt(text_caps) ? 'text-uppercase' : ''}`}
                    {...getAttributes(rest)}
                    ref={ref}
                    placeholder={placeholder}
                    value={compValue}
                    onChange={submitHandler}
                    required={parseInt(is_required)}
                />
                <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>
            </Form.Group>
        </div>
    );
};

export default TextInput;
