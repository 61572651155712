import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Popup } from '../../components';
import TechMetaTags from "./TechMetaTags";
import { useForm } from "react-hook-form";

const TechnicianSignUp = () => {
    
    
    const [showPopup, setShowPopup] = useState('');
    const history = useHistory();
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data,e) => {
        e.preventDefault();

        data.source = 'eco-web';
        fetch(process.env.REACT_APP_ECO_API_URL + '/mobileregister', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }).then(response => response.json()).then(data => {
            let redirectToApp = false, showPoprupParams = {"StatusCode": data.StatusCode, "Message": data.Message};
            if (data.StatusCode == '1') {
                showPoprupParams = {"StatusCode": 1, "customMsg": "User Registration Completed. Please download the App and Login to continue with your Profile completion."};
                redirectToApp = true
            } else if (data.Message == 'User already Exists. Please Login to Continue.') {
                showPoprupParams.StatusCode = 0
                showPoprupParams.title = 'User already Registered'
                showPoprupParams.Message = "Please download the app and Login to continue with your Profile Completion. You can also reset your password by requesting 'Forgot Password'."
                redirectToApp = true
            }
            setShowPopup(showPoprupParams);
            if (redirectToApp) {
                setTimeout(() => window.location.href = 'https://play.google.com/store/apps/details?id=in.droom.eco', 5000);
            }
        }).catch(err => {
            console.log(err);
        })
    }

    /*
    const handleLocation = (pincode) => {
        if (pincode.length == 6) {
            fetch(process.env.REACT_APP_ECO_API_URL + '/locations?cust_pincode=' + pincode, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }).then(response => response.json()).then(data => {
                if (data.StatusCode == "1") {
                    setValue('tech_city', data.Result.city.city_name, { shouldValidate: true });
                    setValue('tech_state', data.Result.state.state_name, { shouldValidate: true });
                    //setTechState(data.Result.state.state_name);
                    clearErrors("tech_city");
                    //setCity(data.Result.city.city_name);
                    clearErrors("tech_state");
                }
            });
        }


    }
    */

    return (
        <div className="col-md">
            <TechMetaTags/>
            {showPopup ? <Popup showPopup={showPopup} /> : null}
            <form onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" {...register("source")} value="eco_web"/>
                <h2 className="fs-4 text-uppercase">Create your account</h2>
                <div className="fs-6 text-gray-light pn-regular mb-4">All Fields are Mandatory</div>
                <div className="form-floating mb-3">
                    <input
                        type="email"
                        className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0"
                        id="techEmail"
                        {...register("tech_email_id", {
                            required: true,
                            pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "invalid email address"} 
                        })}
                        placeholder="tech.name@domain.in"
                    />
                    {errors.tech_email_id?.message && (
                        <p className="alert-danger text-center">{errors.tech_email_id?.message}</p>
                    )}
                    {errors.tech_email_id?.type === 'required' && <p className="alert-danger text-center">Email is required</p>}
                    {errors.tech_email_id?.type === 'email' && <p className="alert-danger text-center">Email is not valid</p>}
                    <label className="ps-0">Email ID</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="number" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="techPhoneNumber" {...register("tech_phone_no", { required: true,minLength:10,maxLength:10 })} placeholder="Phone Number" />
                    {errors.tech_phone_no?.type === 'required' && <p className="alert-danger text-center">Phone is required</p>}
                    {(errors.tech_phone_no?.type === 'minLength' || errors.tech_pincode?.type === 'maxLength') && <p className="alert-danger text-center">Phone number length must be 10 digits</p>}
                    <label className="ps-0" htmlFor="techPhoneNumber">Phone Number</label>
                </div>

                <button type="submit" className="btn btn-primary px-5 mt-lg-5 mt-4 py-2">Submit</button>
                
            </form>
        </div>
    );
}

export default TechnicianSignUp;