const helmet = {
  htmlAttributes: { lang: 'en' },
  titleTemplate: ' %s',
  link: [
    {
      rel: 'stylesheet',
      href: 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css',
      integrity: 'sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC',
      crossorigin: 'anonymous'
    },
    {
      rel: 'stylesheet',
      href: '/css/custom-font.css'

    },
    {
      rel: 'stylesheet',
      href: '/css/common.css'

    },
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: '/icons/favicon.ico'
    }
  ],
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
    },
    {
      name: 'description',
      content: ''
    }
  ]
};

export default helmet;
