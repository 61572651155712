import {useParams} from "react-router-dom";
import { useState } from "react";
import { Popup } from '../../components';
import { useForm } from "react-hook-form";
/*  Yup is a JavaScript schema builder for value parsing and validation. 
Define a schema, transform a value to match, validate the shape of an existing value, or both. 
Yup schema are extremely expressive and allow modeling complex, interdependent validations, or value transformations.
Yup's API is heavily inspired by Joi, but leaner and built with client-side validation as its primary use-case. 
    Yup separates the parsing and validating functions into separate steps. cast() transforms data while validate checks that the input is the correct shape. 
    Each can be performed together (such as HTML form validation) or seperately (such as deserializing trusted data from APIs). 
 *
    */
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import '../../../resources/css/signup.css';

var md5 = require("md5");

const ResetForm = () => {
    let {hash}= useParams();
    const [showPopup, setShowPopup] = useState('');

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('password')], 'Passwords must match')
            
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    
    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data,e) => {
        
        e.preventDefault();

        fetch(process.env.REACT_APP_ECO_API_URL + '/resetpassword?token='
            +data.token+
            '&password='+md5(data.password)+
            '&password_confirmation='+md5(data.confirmPassword), {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        }).then(response => response.json()).then(data => {
            setShowPopup(data);
            setInterval(() => window.location.href = '/', 5000);
        }).catch(err => {
            console.log(err);
        })
    }

    return (
        <div className="container">
            {showPopup.length == 0 ? null : <Popup showPopup={showPopup} />}
            <div className="card shadow rounded-10 p-lg-5 p-4 bg-white border-0 mb-4 mt-4" >
                <div className="row gy-4">
                    <div className="col-md">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" {...register("token")} value={hash} />
                            <h2 className="fs-4 text-uppercase">Reset your password</h2>
                            <div className="fs-6 text-gray-light pn-regular mb-4">All Fields are Mandatory</div>
                            <div className="form-floating mb-3">
                                <input type="password" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="password" {...register("password")} placeholder="Password" />
                                {errors.password ? <p className="text-danger">{errors.password?.message}</p> : ''}
                                <label className="ps-0" htmlFor="techFirstName">Enter Password</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input type="password" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="confirmPassword" {...register("confirmPassword")} placeholder="Confirm Password" />
                                {errors.confirmPassword ? <p className="text-danger">{errors.confirmPassword?.message}</p> : ''}
                                <label className="ps-0" htmlFor="techName">Confirm Password</label>
                            </div>

                            <button type="submit" className="btn btn-primary px-5 mt-lg-5 mt-4 py-2 float-end">Submit</button>
                        </form>
                    </div>
                    <div className="col-md offset-lg-1">
                        <h2 className="fs-4 text-uppercase mb-4">inspection flow for technician</h2>
                        <figure>
                            <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomresponsive/img/content/inspection-flow-technician.jpg"} alt="Inspection flow for technician" className="img-fluid" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ResetForm;
