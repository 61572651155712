const UploadPhotos = ({ inputRef, name, componentOptions, handleFileChange, required }) => {
    return (
        <input
            ref={inputRef}
            type="file"
            accept="image/*"
            capture="camera"
            name={name}
            required={required}
            className="capture-input"
            onChange={handleFileChange}
            multiple={componentOptions.max_images > 1 ? true : false}
        />
    );
};

export default UploadPhotos;
