import '@resources/css/tutorial.css';
import { Form } from 'react-bootstrap';

const SampleVideo = ({ componentOptions, componentDetails }) => {
    const { componentName } = componentDetails;
    return (
        <>
            <Form.Label>{componentName}</Form.Label>
            <div className="video-card text-left text-lg bg-white w-full p-3 mx-auto">
                <div>
                    <div className="video-wrapper d-flex justify-content-center flex-wrap flex-shrink-0">
                        <iframe
                            width="320"
                            height="200"
                            src={componentOptions?.path}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            </div>
        </>
    );
};
export default SampleVideo;
