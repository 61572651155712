import { Helmet } from "react-helmet";
const MetaTags = ()=>{
    return (
        <Helmet>
            <title>Check Motor Vehicle Inspection, Auto Mechanic near me for Cars, Bikes & Scooters</title>
            <meta name="description" content="Used Vehicle Inspection Services- Eco provides automobile inspection report for second hand car, bike, scooter with 121 checkpoint at affordable price in India."/>

            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:site" content="@ecoinspection"/>
            <meta name="twitter:title" content="Auto Inspection Services for Used Bikes, Scooters & Cars"/>
            <meta name="twitter:description" content="ECO Provides Vehicle Inspection Service for Second Hand Car, Bike & Scooter with 121 Checkpoints, we are Offering Complete Automobile Inspections Report at Good Price in all Over India."/>
            <meta name="twitter:creator" content="ecoinspection.in/"/>

            <meta data-react-helmet="true" name="viewport" content=""/>

            <meta name="twitter:image:src" content="//cdn1.droom.in/imgusr/droomEco/content/forTechnician.png"/>
            <meta name="twitter:app:name:googleplay" content="ECO Inspection"/>
            <meta name="twitter:app:url:googleplay" content="https://play.google.com/store/apps/details?id=in.droom.eco"/>


            <meta property="og:title" content="Auto Inspection Services for Used Bikes, Scooters & Cars" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="ecoinspection.in/" /> 
            <meta property="og:image" content="//cdn1.droom.in/imgusr/droomEco/content/forTechnician.png" />
            <meta property="og:description" content="ECO Provides Vehicle Inspection Service for Second Hand Car, Bike & Scooter with 121 Checkpoints, we are Offering Complete Automobile Inspections Report at Good Price in all Over India." />
            <meta property="og:site_name" content="@ecoinspection" />
            <meta property="og:locale" content="en_US" />


            <meta itemprop="name" content="Auto Inspection Services for Used Bikes, Scooters & Cars"/>
            <meta itemprop="description" content="ECO Provides Vehicle Inspection Service for Second Hand Car, Bike & Scooter with 121 Checkpoints, we are Offering Complete Automobile Inspections Report at Good Price in all Over India."/>
            <meta itemprop="image" content="//cdn1.droom.in/imgusr/droomEco/content/forTechnician.png"/>
            <link rel="canonical" href="https://ecoinspection.in/"></link>
        </Helmet>
    );
}
export default MetaTags;
