import { Link, withRouter } from "react-router-dom";
const Footer = () => {
    return (
        <>

            <footer className="footer pb-2 footer-bg-img">
                <div className="container py-md-4 py-1 rounded-3 shadow bg-white">
                    <div className="px-3 px-md-0">
                        <div className="d-flex justify-content-md-around flex-column flex-md-row">
                            <ul className="list-group list-group-flush ">
                                <li className="list-group-item border-0 px-0 text-capitalize fs-5 cp-medium">order inspection</li>
                                <li className="list-group-item border-0 px-0 text-capitalize">
                                    <Link to="/car-inspection" className="pn-regular fs-6 text-decoration-none text-gray">Cars</Link>

                                </li>
                                {/* <li className="list-group-item border-0 px-0 text-capitalize">
                                <Link to="#" className="pn-regular fs-6 text-decoration-none text-gray">suvs</Link>
                            </li> */}
                                <li className="list-group-item border-0 px-0 text-capitalize">
                                    <Link to="/bike-inspection" className="pn-regular fs-6 text-decoration-none text-gray">bikes</Link>
                                </li>
                                <li className="list-group-item border-0 px-0 text-capitalize">
                                    <Link to="/scooter-inspection" className="pn-regular fs-6 text-decoration-none text-gray">scooters</Link>
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush ">
                                <li className="list-group-item border-0 px-0 text-capitalize fs-5 cp-medium">follow us</li>
                                <li className="list-group-item border-0 px-0 text-capitalize">
                                    <a href="https://www.facebook.com/ecoautoinspection" rel="noreferrer" target="_blank" className="d-flex pn-regular fs-6 text-decoration-none text-gray"><span className="sprite facebook me-1"></span>facebook</a>
                                </li>
                                <li className="list-group-item border-0 px-0 text-capitalize">
                                    <a href="https://twitter.com/ecoinspection" rel="noreferrer" target="_blank" className="d-flex pn-regular fs-6 text-decoration-none text-gray"><span className="sprite twitter me-1"></span> twitter</a>
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush ">
                                <li className="list-group-item border-0 px-0 text-capitalize fs-5 cp-medium d-none d-md-block">&nbsp;</li>
                                <li className="list-group-item border-0 px-0 text-capitalize">
                                    <Link to="/privacy-policy" rel="noreferrer" className="pn-regular fs-6 text-decoration-none text-gray">privacy policy</Link>
                                </li>
                                <li className="list-group-item border-0 px-0 text-capitalize">
                                    <Link to="/terms-conditions" rel="noreferrer" className="pn-regular fs-6 text-decoration-none text-gray">terms & conditions</Link>
                                </li>
                                <li className="list-group-item border-0 px-0 text-capitalize">
                                    <a href="https://sandeepaggarwal.com/" target="_blank" rel="noreferrer" className="pn-regular fs-6 text-decoration-none text-gray">sandeep aggarwal</a>
                                </li>
                            </ul>
                            <ul className="list-group list-group-flush ">
                                <li className="list-group-item border-0 px-0 text-capitalize fs-5 cp-medium">
                                    <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomresponsive/img/content/eco-footer-logo.png"} alt="ECO" className="img-fluid" />
                                </li>
                                <li className="list-group-item border-0 px-0">
                                    <a href="mailto:eco@droom.in" rel="noreferrer" className="pn-regular fs-6 text-decoration-none text-gray">eco@droom.in</a>
                                </li>
                                <li className="list-group-item border-0 px-0 pt-0">
                                    <a href="tel:9650080808" rel="noreferrer" className="pn-regular fs-6 text-decoration-none text-gray">9650 08 08 08</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex px-md-5 mt-md-4 fs-11 text-gray-light pn-regular">
                        <div className="flex-grow-1 ps-md-4">© All Rights Reserved By <a href="https://droom.in/" target="_blank" rel="noreferrer" className="text-decoration-none">Droom.in</a></div>
                        <div className="pe-md-4">a service by <a href="https://droom.in/" target="_blank" rel="noreferrer"><img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomresponsive/img/content/droom-logo-footer.png"} alt="Droom.in" className="img-fluid align-middle" /></a></div>
                    </div>
                </div>
            </footer>


        </>
    );
}

export default Footer;