import { useEffect } from "react";
import ResetForm from "./ResetForm";
import InnerPageBanner from '../InnerPageBanner';

const Main = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <InnerPageBanner heading={'Reset Your Password'} bgImage={'signup-bg'} />
            <ResetForm />
        </>
    );
}
export default Main;
