import { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import CustomRadioButtons from './CustomRadioButtons';
import { useDispatch } from 'react-redux';
import { updateComponentFields } from '@redux/FormRedux/reducers/formSlice';

const RadioInput = ({ componentOptions, componentDetails }) => {
    const [validationError, setValidationError] = useState('Please select one of these options.');
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedKey, setSelectedKey] = useState('');
    const { id, title: compTitle, is_required = 0, options = [], ...rest } = componentOptions;
    const {
        components,
        parameterName,
        setCompList,
        parameterId,
        componentId = null,
        componentName = null,
        type = null
    } = componentDetails;

    const handleOptionChange = (event, option, key) => {
        setValidationError(event.target.validationMessage);
        setSelectedOption(option);
        setSelectedKey(key);
        dispatch(
            updateComponentFields({
                parameterId,
                parameterName,
                componentId,
                name: componentName,
                title: compTitle,
                type,
                optionId: id,
                value: option,
                optionKey: key
            })
        );
    };

    useEffect(() => {
        if (!componentOptions.events || !selectedKey) {
            return;
        }
        let hideComps = [];
        const events = componentOptions.events.filter(event => event.option_id == selectedKey);
        events.forEach(event => {
            if (event.impact_level !== 'component') {
                return;
            }
            event.action.forEach(act => {
                if (act.impact_type === 'hide') {
                    hideComps.push(act.component_id);
                }
            });
        });

        setCompList(components.filter(comp => !hideComps.some(com => com == comp.id)));
    }, [selectedKey]);

    const inputName = compTitle ? compTitle : id === 'id' ? componentName : id;

    return (
        <div className="radio-group-el">
            <Form.Group className="bg-white p-3 rounded border">
                <div className="d-flex flex-column gap-2">
                    <p className="text-capitalize">{inputName.replace(/_/g, ' ')}</p>
                    {Object.keys(options).map(key => (
                        <CustomRadioButtons
                            key={key}
                            validationError={validationError}
                            name={inputName}
                            required={parseInt(is_required)}
                            radioLabel={options[key]}
                            options={options}
                            optionKey={key}
                            selectedOption={selectedOption}
                            handleOptionChange={handleOptionChange}
                        />
                    ))}
                </div>
            </Form.Group>
        </div>
    );
};

export default RadioInput;
