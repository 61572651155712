const ImageView = ({ componentOptions }) => {
    const { id, height, path, is_visible } = componentOptions;
    return (
        <>
            {is_visible && (
                <img
                    key={id}
                    style={{ height: `${height * 3}px` }}
                    src={`https://cdn1.acedms.com/photos/images/web/beta/images/pages/${path}`}
                    className="uploaded-img w-100 mb-4"
                    alt="Uploaded image"
                />
            )}
        </>
    );
};

export default ImageView;
