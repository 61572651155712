import React, { useEffect, useState } from 'react';
import {useParams} from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import '../../resources/css/custom.css';



const ImageSlider=()=>{
        const style = {
            width:"50%",
            margin:"auto"
        }
        let {order_id,hash}= useParams();
        const [images,setImages] = useState([]);
        useEffect(()=>{
            console.log(process.env.REACT_APP_ECO_API_URL,order_id,hash);
            fetch(process.env.REACT_APP_ECO_API_URL + '/report_photos/'+order_id+'/'+hash, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                
            }).then(response => response.json()).then(data => {
                if(data.StatusCode==1){
                    setImages(data.Result.photos);
                }
                console.log(data);
            });
        },[order_id,hash]);


    return (
        
        <div className="container">
            <Carousel showArrows={true} showIndicators={false} centerMode={true} centerSlidePercentage={100}>
                {
                    images ? images.map(row=>(
                        <div style={style} key={row.toString()}>
                            <img src={row} alt={row}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = process.env.REACT_APP_CDN_DOMAIN + "/photos/images/droomresponsive/img/content/eco/eco-icon.svg";
                                }}
                            />
                        </div>
                    )) : ''
                }
            </Carousel>
        </div>
    
    );
}

export default ImageSlider;