import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom'; 
import './Header.css';
const Header = ({ menu }) => {
    const history = useHistory();
    return (
        <Navbar className="nav-items bg-white" sticky="top">
            <Container>
                <ul>
                    <li className="pe-3">
                        <img
                            src={`${process.env.REACT_APP_CDN_DOMAIN}/photos/images/eco/dashboard/back.svg`}
                            alt="ECO Inspection"
                            className="back-icon"
                            onClick={() => history.goBack()}
                        />
                    </li>
                    <li className="flex-grow-1 fs-5">{menu}</li>
                    <li>
                        <a href="tel:+916301233279" >
                            <img src={`${process.env.REACT_APP_CDN_DOMAIN}/photos/images/eco/dashboard/call.svg`} alt="" className='w-75 h-75' />
                        </a>
                    </li>
                </ul>
            </Container>
        </Navbar>
    );
};

export default Header;
