import InnerPageBanner from '../InnerPageBanner';
import '../../../resources/css/features.css';
import '../../../resources/css/service.css';
import { useEffect } from "react";
import MetaTags from './MetaTags';

const Main = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <MetaTags />
            <InnerPageBanner heading={'ECO 360 Degree Tour of Vehicle Inspection'} bgImage={'features-bg'} />
            <ul className="nav nav-tabs m-auto pt-5 pb-3 d-table border-0 eco-custom-tabs" id="featuresTab" role="tablist">
                <li className="nav-item d-table-cell" role="presentation">
                    <button className="nav-link text-capitalize cp-medium fs-5 lh-1 active" id="four-wheelers-tab" data-bs-toggle="tab" data-bs-target="#four-wheelers" type="button" role="tab" aria-controls="four-wheelers" aria-selected="true">four wheelers</button>
                </li>
                <li className="nav-item d-table-cell" role="presentation">
                    <button className="nav-link text-capitalize cp-medium fs-5 lh-1" id="two-wheelers-tab" data-bs-toggle="tab" data-bs-target="#two-wheelers" type="button" role="tab" aria-controls="two-wheelers" aria-selected="false">two wheelers</button>
                </li>
            </ul>
            <div className="tab-content features-tab-content p-0 p-md-4" id="offering">
                <div className="tab-pane fade show active" id="four-wheelers" role="tabpanel" aria-labelledby="four-wheelers-tab">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className="card-title p-3 fs-5 border-bottom border-1 border-gray mb-0">Inspection Checkpoints for Four Wheeler</div>
                                    <div className="d-flex align-items-stretch">
                                        <div className="nav bg-light-blue flex-column nav-pills cp-regular bg-light-blue w-40 text-left" id="v-pills-cars-tab" role="tablist" aria-orientation="vertical">
                                            <button className="nav-link position-relative text-dark my-1 mt-3 active" id="v-pills-cars-first-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cars-first" type="button" role="tab" aria-controls="v-pills-cars-first" aria-selected="true">Visual and Functional Assessment</button>
                                            <button className="nav-link position-relative text-dark my-1" id="v-pills-cars-second-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cars-second" type="button" role="tab" aria-controls="v-pills-cars-second" aria-selected="false">Under the Hood</button>
                                            <button className="nav-link position-relative text-dark my-1" id="v-pills-cars-third-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cars-third" type="button" role="tab" aria-controls="v-pills-cars-third" aria-selected="false">Checking the Vehicle Exterior</button>
                                            <button className="nav-link position-relative text-dark my-1" id="v-pills-cars-fourth-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cars-fourth" type="button" role="tab" aria-controls="v-pills-cars-fourth" aria-selected="false">Wheel Assembly</button>
                                            <button className="nav-link position-relative text-dark my-1" id="v-pills-cars-fifth-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cars-fifth" type="button" role="tab" aria-controls="v-pills-cars-fifth" aria-selected="false">Checking the Vehicle Interior</button>
                                            <button className="nav-link position-relative text-dark my-1" id="v-pills-cars-sixth-tab" data-bs-toggle="pill" data-bs-target="#v-pills-cars-sixth" type="button" role="tab" aria-controls="v-pills-cars-sixth" aria-selected="false">Engine, Transmission, Axles and Exhaust</button>
                                        </div>
                                        <div className="tab-content px-md-5 p-3 py-md-4 pe-md-4 pe-0 bg-white feature-content" id="v-pills-cars-tabContent">
                                            <div className="tab-pane fade py-md-4 px-md-0 px-3 show active" id="v-pills-cars-first" role="tabpanel" aria-labelledby="v-pills-cars-first-tab">
                                                <h2 className="fs-6 text-gray-light text-uppercase text-center mb-md-5 mb-2 cp-bold">Visual and Functional Assessment</h2>
                                                <div className="row">
                                                    <div className="col-md-4 offset-lg-1 col-lg-3">
                                                        <ul className="list-unstyled feature-list-items pn-regular text-gray-light text-capitalize lh-sm">
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Manufacturer
                                                                Name/ Make</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Model</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Trim/Vaiant
                                                                of the Car</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Manufacturing
                                                                Year</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">color</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">fuel type</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-10 offset-lg-1 col-lg-7">
                                                        <figure className="figure mt-4">
                                                            <img src={process.env.REACT_APP_CDN_DOMAIN+"/photos/images/droomresponsive/img/content/feature-img5.png"} className="img-fluid" alt="Feature | ECO" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade py-md-4 px-md-0 px-3" id="v-pills-cars-second" role="tabpanel" aria-labelledby="v-pills-cars-second-tab">
                                                <h2 className="fs-6 text-gray-light text-uppercase text-center mb-md-5 mb-2 cp-bold">under the hood</h2>
                                                <div className="row">
                                                    <div className="col-md-4 offset-lg-1 col-lg-3">
                                                        <ul className="list-unstyled feature-list-items pn-regular text-gray-light text-capitalize lh-sm">
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Coolant
                                                                Hoses</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Belts /
                                                                Tensioners /
                                                                Pulleys</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Wiring</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Water
                                                                Pump</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Coolant &
                                                                Radiator
                                                                Functioning</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Ignition
                                                                System</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-10 offset-lg-1 col-lg-7">
                                                        <figure className="figure mt-4">
                                                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/photos/images/droomresponsive/img/content/feature-img4.png"} className="img-fluid" alt="Feature | ECO" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade py-md-4 px-md-0 px-3" id="v-pills-cars-third" role="tabpanel" aria-labelledby="v-pills-cars-third-tab">
                                                <h2 className="fs-6 text-gray-light text-uppercase text-center mb-md-5 mb-2 cp-bold">Checking the Vehicle Exterior</h2>
                                                <div className="row">
                                                    <div className="col-md-4 offset-lg-1 col-lg-3">
                                                        <ul className="list-unstyled feature-list-items pn-regular text-gray-light text-capitalize lh-sm">
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Brake
                                                                Lights</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Flasher &
                                                                Hazard Lamps</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Trunk / Boot
                                                                Lamps</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Convertible Top (If applicable)</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Trunk
                                                                Handle</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Left & Right
                                                                side window</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-10 offset-lg-1 col-lg-7">
                                                        <figure className="figure mt-4">
                                                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/photos/images/droomresponsive/img/content/feature-img2.png"} className="img-fluid" alt="Feature | ECO" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade py-md-4 px-md-0 px-3" id="v-pills-cars-fourth" role="tabpanel" aria-labelledby="v-pills-cars-fourth-tab">
                                                <h2 className="fs-6 text-gray-light text-uppercase text-center mb-md-5 mb-2 cp-bold">Wheel Assembly</h2>
                                                <div className="row">
                                                    <div className="col-md-4 offset-lg-1 col-lg-3">
                                                        <ul className="list-unstyled feature-list-items pn-regular text-gray-light text-capitalize lh-sm">
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Tires Match (all in same direction)</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Properly Inflated</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Check Grooves
                                                                and Threads in
                                                                all Tires</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Proper Shape</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Spare Wheel</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Spare Tire
                                                                Inflated adequately</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-10 offset-lg-1 col-lg-7">
                                                        <figure className="figure mt-4">
                                                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/photos/images/droomresponsive/img/content/feature-img6.png"} className="img-fluid" alt="Feature | ECO" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade py-md-4 px-md-0 px-3" id="v-pills-cars-fifth" role="tabpanel" aria-labelledby="v-pills-cars-fifth-tab">
                                                <h2 className="fs-6 text-gray-light text-uppercase text-center mb-md-5 mb-2 cp-bold">Checking the Vehicle Interior</h2>
                                                <div className="row">
                                                    <div className="col-md-4 offset-lg-1 col-lg-3">
                                                        <ul className="list-unstyled feature-list-items pn-regular text-gray-light text-capitalize lh-sm">
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Door
                                                                Panels</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Carpet /
                                                                Floor Mats</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Cup
                                                                Holders</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Seats and
                                                                Seat Belts</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Meters</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Fuel</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Temperature
                                                                Gauges</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Turn & Light
                                                                Switches</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-10 offset-lg-1 col-lg-7">
                                                        <figure className="figure mt-4">
                                                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/photos/images/droomresponsive/img/content/feature-img3.png"} className="img-fluid" alt="Feature | ECO" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade py-md-4 px-md-0 px-3" id="v-pills-cars-sixth" role="tabpanel" aria-labelledby="v-pills-cars-sixth-tab">
                                                <h2 className="fs-6 text-gray-light text-uppercase text-center mb-md-5 mb-2 cp-bold">Engine, Transmission, Axles and Exhaust</h2>
                                                <div className="row">
                                                    <div className="col-md-10 offset-lg-1 col-lg-7">
                                                        <figure className="figure mt-4">
                                                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/photos/images/droomresponsive/img/content/feature-img.png"} className="img-fluid" alt="Feature | ECO" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="two-wheelers" role="tabpanel" aria-labelledby="two-wheelers-tab">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="card">
                                    <div className="card-title p-3 fs-5 border-bottom border-1 border-gray mb-0">Inspection Checkpoints for Two Wheeler</div>
                                    <div className="d-flex align-items-stretch">
                                        <div className="nav bg-light-blue flex-column nav-pills cp-regular bg-light-blue w-40 text-left" id="v-pills-bikes-tab" role="tablist" aria-orientation="vertical">
                                            <button className="nav-link position-relative text-dark my-1 mt-3 active" id="v-pills-bikes-first-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bikes-first" type="button" role="tab" aria-controls="v-pills-bikes-first" aria-selected="true">Tires & Wheels (Front & Rear)</button>
                                            <button className="nav-link position-relative text-dark my-1" id="v-pills-bikes-second-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bikes-second" type="button" role="tab" aria-controls="v-pills-bikes-second" aria-selected="false">Checking L-Lights & Electrics</button>
                                            <button className="nav-link position-relative text-dark my-1" id="v-pills-bikes-third-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bikes-third" type="button" role="tab" aria-controls="v-pills-bikes-third" aria-selected="false">Checking Vehicle C-Controls (Front & Rear)</button>
                                            <button className="nav-link position-relative text-dark my-1" id="v-pills-bikes-fourth-tab" data-bs-toggle="pill" data-bs-target="#v-pills-bikes-fourth" type="button" role="tab" aria-controls="v-pills-bikes-fourth" aria-selected="false">Vehicle Stands</button>
                                        </div>
                                        <div className="tab-content px-md-5 p-3 py-md-4 bg-white feature-content" id="v-pills-bikes-tabContent">
                                            <div className="tab-pane fade py-md-4 px-md-0 px-3 show active" id="v-pills-bikes-first" role="tabpanel" aria-labelledby="v-pills-bikes-first-tab">
                                                <h2 className="fs-6 text-gray-light text-uppercase text-center mb-md-5 mb-2 cp-bold">Tires & Wheels (Front & Rear)</h2>
                                                <div className="row">
                                                    <div className="col-md-4 offset-lg-1 col-lg-3">
                                                        <ul className="list-unstyled feature-list-items pn-regular text-gray-light text-capitalize lh-sm">
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Wheel
                                                                Spokes</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Wheel
                                                                Cast</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Wheel
                                                                Bearings</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Wheel
                                                                Seal</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-10 offset-lg-1 col-lg-7">
                                                        <figure className="figure mt-4">
                                                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/photos/images/droomresponsive/img/content/feature-img-b2.png"} className="img-fluid" alt="Feature | ECO" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade py-md-4 px-md-0 px-3" id="v-pills-bikes-second" role="tabpanel" aria-labelledby="v-pills-bikes-second-tab">
                                                <h2 className="fs-6 text-gray-light text-uppercase text-center mb-md-5 mb-2 cp-bold">Checking L-Lights & Electrics</h2>
                                                <div className="row">
                                                    <div className="col-md-4 offset-lg-1 col-lg-3">
                                                        <ul className="list-unstyled feature-list-items pn-regular text-gray-light text-capitalize lh-sm">
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Switches
                                                                Operation</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Switches
                                                                Condition</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Lenses &
                                                                Reflectors Condition</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Wiring
                                                                Condition &
                                                                Routing</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-10 offset-lg-1 col-lg-7">
                                                        <figure className="figure mt-4">
                                                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/photos/images/droomresponsive/img/content/feature-img-b1.png"} className="img-fluid" alt="Feature | ECO" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade py-md-4 px-md-0 px-3" id="v-pills-bikes-third" role="tabpanel" aria-labelledby="v-pills-bikes-third-tab">
                                                <h2 className="fs-6 text-gray-light text-uppercase text-center mb-md-5 mb-2 cp-bold">Checking Vehicle C-Controls (Front & Rear)</h2>
                                                <div className="row">
                                                    <div className="col-md-4 offset-lg-1 col-lg-3">
                                                        <ul className="list-unstyled feature-list-items pn-regular text-gray-light text-capitalize lh-sm">
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Clutch
                                                                Operation</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Cluster
                                                                Meter</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Fasteners
                                                                (Clips & Cotter Pins)</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-10 offset-lg-1 col-lg-7">
                                                        <figure className="figure mt-4">
                                                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/photos/images/droomresponsive/img/content/feature-img-b3.png"} className="img-fluid" alt="Feature | ECO" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade py-md-4 px-md-0 px-3" id="v-pills-bikes-fourth" role="tabpanel" aria-labelledby="v-pills-bikes-fourth-tab">
                                                <h2 className="fs-6 text-gray-light text-uppercase text-center mb-md-5 mb-2 cp-bold">Vehicle Stands</h2>
                                                <div className="row">
                                                    <div className="col-md-4 offset-lg-1 col-lg-3">
                                                        <ul className="list-unstyled feature-list-items pn-regular text-gray-light text-capitalize lh-sm">
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Centre Stand
                                                                Retention & Condition</li>
                                                            <li className="my-4 position-relative ps-sm-5 ps-md-2">Side Stand
                                                                Retention & Condition</li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-10 offset-lg-1 col-lg-7">
                                                        <figure className="figure mt-4">
                                                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/photos/images/droomresponsive/img/content/feature-img-b4.png"} className="img-fluid" alt="Feature | ECO" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container my-4">
                <div className="row gy-4">
                    <div className="col-lg-12">
                        <figure className="d-block mb-0 text-center">
                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/photos/images/droomresponsive/img/content/setting-img.png"} alt="ECO Features" className="img-fluid" />
                        </figure>
                        <h3 className="fs-3 text-center mt-0 mb-md-3 mb-2 lh-sm cp-medium text-gray-light text-uppercase">Get It Right By Choosing Right With ECO Features</h3>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="card text-center d-flex justify-content-center flex-col card-bg-light shadow p-3 rounded text-dark border-0">
                            <div className="bg-info-light p2 rounded-circle m-auto">
                                <div className="sprite buyer"></div>
                            </div>
                            <div className="fs-5 my-3 cp-medium">For Buyer</div>
                            <ul className="list-unstyled text-center text-capitalize">
                                <li className="pn-regular fs-6 text-gray-light my-2">Digital Report</li>
                                <li className="pn-regular fs-6 text-gray-light my-2">Quick SLA</li>
                                <li className="pn-regular fs-6 text-gray-light my-2">Fair Market Vehicle Valuation</li>
                                <li className="pn-regular fs-6 text-gray-light my-2">Exhaustive Inspection Checklist</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="card text-center d-flex justify-content-center flex-col card-bg-light shadow p-3 rounded text-dark border-0">
                            <div className="bg-info-light p2 rounded-circle m-auto">
                                <div className="sprite seller"></div>
                            </div>
                            <div className="fs-5 my-3 cp-medium">For Seller</div>
                            <ul className="list-unstyled text-center text-capitalize">
                                <li className="pn-regular fs-6 text-gray-light my-2">Door Step Service Delivery</li>
                                <li className="pn-regular fs-6 text-gray-light my-2">Improve Vehicle Attractiveness</li>
                                <li className="pn-regular fs-6 text-gray-light my-2">Realize Optimal Price</li>
                                <li className="pn-regular fs-6 text-gray-light my-2">Sell Faster</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                        <div className="card text-center d-flex justify-content-center flex-col card-bg-light shadow p-3 rounded text-dark border-0">
                            <div className="bg-info-light p2 rounded-circle m-auto">
                                <div className="sprite technician"></div>
                            </div>
                            <div className="fs-5 my-3 cp-medium">For Technicians</div>
                            <ul className="list-unstyled text-center text-capitalize">
                                <li className="pn-regular fs-6 text-gray-light my-2">Earn 2-3x Money</li>
                                <li className="pn-regular fs-6 text-gray-light my-2">Get Trained Online</li>
                                <li className="pn-regular fs-6 text-gray-light my-2">Be your own Boss</li>
                                <li className="pn-regular fs-6 text-gray-light my-2">Timely Payment</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Main;