import React from 'react';
import RangeSliderWithLabels from './RangeSliderWithLabels';
import PageCount from './PageCount';

const SubHeader = ({ screenNo, screenName, screenCount, showPageCount = true }) => {
    return (
        <div className="sub-header p-3">
            <RangeSliderWithLabels />
            {showPageCount && <PageCount screenNo={screenNo} screenName={screenName} screenCount={screenCount} />}
        </div>
    );
};

export default SubHeader;
