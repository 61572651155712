import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { updateImages, resetImages } from '@redux/AIRedux/aiBasedSlice';
import { useDispatch } from 'react-redux';
const SignaturePad = ({ componentDetails }) => {
    const { componentCode } = componentDetails;
    const dispatch = useDispatch();
    const sigCanvas = useRef(null);

    const clear = () => {
        dispatch(resetImages({ name: componentCode }));
        sigCanvas.current.clear();
    };

    function resizeImage(file, width, height, callback) {
        const reader = new FileReader();

        reader.onload = function (event) {
            const img = new Image();

            img.onload = function () {
                // Create a canvas to draw the image with the new dimensions
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set canvas dimensions to the desired width and height
                canvas.width = width;
                canvas.height = height;

                // Draw the image on the canvas with new dimensions
                ctx.drawImage(img, 0, 0, width, height);

                // Convert the canvas back to a Blob or File
                canvas.toBlob(function (blob) {
                    const resizedImage = new File([blob], 'resized_image.png', {
                        type: 'image/png'
                    });

                    // Call the callback with the resized file
                    callback(resizedImage);
                }, 'image/png');
            };

            // Set the source of the image to the result of FileReader
            img.src = event.target.result;
        };

        // Read the file as a data URL
        reader.readAsDataURL(file);
    }
    const imageResetAndUpdate = (componentCode, resizedImage) => {
        dispatch(resetImages({ name: componentCode }));
        dispatch(
            updateImages({
                name: componentCode,
                image: resizedImage
            })
        );
    };
    const save = () => {
        const canvas = sigCanvas.current.getTrimmedCanvas();
        canvas.toBlob(blob => {
            resizeImage(new File([blob], 'image.png', { type: blob.type }), 900, 900, function (resizedImage) {
                // Now you have the resized image in the resizedImage File object
                imageResetAndUpdate(componentCode, resizedImage);
            });
        });
    };

    return (
        <Container className="signature-board">
            <Row className="pt-2">
                <Col className="border border-2">
                    <SignatureCanvas
                        penColor="black"
                        canvasProps={{ width: 300, height: 300, className: 'sigCanvas' }}
                        ref={sigCanvas}
                        onEnd={save}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Button variant="primary" onClick={clear}>
                        Clear
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default SignaturePad;
