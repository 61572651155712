import { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import SubHeader from '../Common/SubHeader';
import Loader from '../Common/Loader';
import { updateBucketField } from '@redux/FormRedux/reducers/formSlice';
import { updateBucketList } from '@redux/FormRedux/reducers/bucketSlice';
import GuideLineModal from '../Modals/GuidelinesModal';
import { COMPLETED, INSPECTION_STARTED } from '../Order/Statuses';

const Buckets = () => {
    const [loading, setLoading] = useState(true);
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [bucketList, setBucketList] = useState([]);
    const [checkGuideline, setCheckGuideline] = useState(true);
    const handleCheckGuideline = () => {
        setCheckGuideline(false);
    };

    const { orderStatus, aiBased } = useSelector(state => state.order);

    useEffect(() => {
        axios.get(`/orders/${params.orderId}/json/buckets`).then(res => {
            if (res.data.StatusCode == 1) {
                setCheckGuideline(!res.data.Result.some(item => item.filledScreens));
                const allCompleted = res.data.Result.every(item => item.filledScreens === item.totalScreens);
                if (allCompleted && orderStatus !== COMPLETED) {
                    axios
                        .post(`/orders/${params.orderId}/inspection/complete`)
                        .then(() => history.push('../completed'));
                } else {
                    setBucketList(res.data.Result);
                    setLoading(false);
                }
            }
        });
    }, [params.orderId]);

    useEffect(()=>{
        if(aiBased){
            history.push('../inspection/start-ai');
        }
    },[params.orderId]);

    useEffect(() => {
        if (bucketList) {
            dispatch(updateBucketList(bucketList));
        }
    }, [bucketList, dispatch]);

    const getScreens = (id, name) => {
        dispatch(updateBucketField({ bucketName: name, bucketId: id }));
        history.push(`buckets/${id}`);
    };

    useEffect(() => {
        if (orderStatus !== INSPECTION_STARTED) {
            history.push(`../start`);
        }
    }, [orderStatus]);

    if (loading) {
        return <Loader />;
    }

    // if (checkGuideline) {
    //     return <GuideLineModal acceptGuidelines={handleCheckGuideline} />;
    // }

    const GetFilledStatus = ({ bucket }) => {
        if (!bucket.filledScreens) {
            return (
                <img
                    src="https://cdn1.droom.in/photos/images/eco/dashboard/warning.svg"
                    className="basket-status-icon"
                    alt="Basket Status Icon"
                />
            );
        }

        if (bucket.filledScreens == bucket.totalScreens) {
            return (
                <img
                    src="https://cdn1.droom.in/photos/images/eco/dashboard/tick.svg"
                    className="basket-status-icon"
                    alt="Basket Status Icon"
                />
            );
        }

        return (
            <div className="number-of-pages text-center text-white py-1 rounded">
                {bucket.filledScreens}/<span>{bucket.totalScreens}</span>
            </div>
        );
    };

    return (
        <>
            <SubHeader showPageCount={false} />
            <div className="p-3">
                <>
                    {bucketList?.map(bucket => (
                        <Card
                            key={bucket.id}
                            onClick={() => getScreens(bucket.id, bucket.name)}
                            className="my-2 rounded shadow-sm p-3 basket d-flex align-items-center justify-content-between gap-3 flex-row"
                        >
                            <div className="basket-content fs-5">{bucket.name}</div>
                            <div className="icon-el">
                                <GetFilledStatus bucket={bucket} />
                            </div>
                        </Card>
                    ))}
                </>
            </div>
        </>
    );
};

export default Buckets;
