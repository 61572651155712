import React from 'react'

const TickSign = () => {
return (
    <div className="w-25 p-2 d-flex justify-content-center align-items-center">
        <img
            width={30}
            height={30}
            src="https://cdn1.droom.in/photos/images/eco/dashboard/tick.svg"
            alt=""
        />
    </div>
)
}

export default TickSign
