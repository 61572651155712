import React, { Fragment } from 'react';
import Component from '@pages/SelfInspection/AI/Components/Component';
const Parameter = ({paramId, components }) => {
    return (
        <div>
            {components?.map((component, index) => {
                return (
                    <Fragment key={index}>
                        <Component index={index} component={component} />
                    </Fragment>
                );
            })}
        </div>
    );
};

export default Parameter;
