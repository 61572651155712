import { Link } from "react-router-dom";
import React, { useRef } from 'react';


const Banner = () => {
    const handleSlider = (n) => {
        window.currentSlide(n);
    }
    return (
        <div className="bannerSection">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h1 className="fs-1 cp-bold lh-sm mt-lg-5 mt-3">Inspect Vehicle Within <br />
                            Few Minutes</h1>
                        <div className="slideshow-container mt-4">
                            <div className="slideshow-inner pn-regular">
                                {/* <div className="mt-2 mb-3">
                                    <span className="dot position-relative fs-6 cursor-pointer ps-4 cp-medium active" onClick={() => handleSlider(1)}>Buyer</span>
                                    <span className="dot position-relative fs-6 cursor-pointer ps-4 cp-medium" onClick={() => handleSlider(2)}>Seller</span>
                                    <span className="dot position-relative fs-6 cursor-pointer ps-4 cp-medium" onClick={() => handleSlider(3)}>Technician</span>
                                </div> */}
                                <div className="slides selected" >
                                    <ul className="rounded-3 list-unstyled px-lg-4 px-2 py-2 py-lg-3 d-flex align-content-start flex-wrap">
                                        <li className="position-relative ps-3 pn-regular my-1"> Digital Report</li>
                                        <li className="position-relative ps-3 pn-regular my-1"> Quick SLA</li>
                                        <li className="position-relative ps-3 pn-regular my-1"> Fair Market Vehicle Valuation</li>
                                        <li className="position-relative ps-3 pn-regular my-1"> Exhaustive Inspection Checklist</li>
                                    </ul>
                                    <div className="d-flex mt-4">
                                        <a href={process.env.REACT_APP_DROOM_WEBSITE_URL + '/eco/repair-estimate'} className="btn btn-outline-primary me-3 text-capitalize">Check repair estimate</a>
                                        <a href={process.env.REACT_APP_DROOM_WEBSITE_URL + '/inspection'} className="btn btn-primary text-capitalize">Book Inspection</a>
                                    </div>
                                </div>
                                {/* <div className="slides" >
                                    <ul className="rounded-3 list-unstyled px-lg-4 px-2 py-2 py-lg-3 d-flex align-content-start flex-wrap">
                                        <li className="position-relative ps-3 pn-regular my-1"> Door Step Service Delivery</li>
                                        <li className="position-relative ps-3 pn-regular my-1"> Improve Vehicle Attractiveness</li>
                                        <li className="position-relative ps-3 pn-regular my-1"> Realize Optimal Price</li>
                                        <li className="position-relative ps-3 pn-regular my-1"> Sell Faster</li>
                                    </ul>
                                    <div className="d-flex mt-4">
                                        <a href="/signup?for=vendor" className="btn btn-outline-primary me-3 text-capitalize">Register as Vendor</a>
                                        <a href={process.env.REACT_APP_DROOM_WEBSITE_URL + '/inspection'} className="btn btn-primary text-capitalize">Book Inspection</a>
                                    </div>
                                </div>
                                <div className="slides">
                                    <ul className="rounded-3 list-unstyled px-lg-4 px-2 py-2 py-lg-3 d-flex align-content-start flex-wrap">
                                        <li className="position-relative ps-3 pn-regular my-1"> Earn 2-3x Money</li>
                                        <li className="position-relative ps-3 pn-regular my-1"> Get Trained Online</li>
                                        <li className="position-relative ps-3 pn-regular my-1"> Be your own Boss</li>
                                        <li className="position-relative ps-3 pn-regular my-1"> Timely Payment</li>
                                    </ul>
                                    <div className="d-flex mt-4">
                                        <Link to="/signup" className="btn btn-primary text-capitalize">Register as Technician</Link>

                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Banner;