import { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
const Popup = ({ showPopup }) => {
    const [show, setShow] = useState(true);

    const handleClose = () => {
        setShow(false);
        window.location.reload();
    };
    const handleShow = () => setShow(true);

    return (
        <>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title style={showPopup.StatusCode == "1" ? { color: '#259a2a' } : showPopup.StatusCode == "0" ? { color: '#9F7474' } : { color: '#FF0000' }}>
                        {showPopup.title || (showPopup.StatusCode == "1" ? "Success !!" : "Failed !!")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{showPopup.StatusCode == "1" ? showPopup.customMsg == "" ? "Details captured successfully." : showPopup.customMsg : showPopup.Message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Popup;