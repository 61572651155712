import { Helmet } from "react-helmet";
const TechMetaTags = () => {
    return (
        
        <Helmet>
            <title> Sign Up | ECO</title>
            <meta name="description" content="Create an account to be a ECO Certified Technician."/>
            {/* <!-- Twitter Card data --/> */}
            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:site" content="@ecoinspection"/>
            <meta name="twitter:title" content="Create an account to be a ECO Certified Technician."/>
            <meta name="twitter:description" content="Create an account to be a ECO Certified Technician."/>
            <meta name="twitter:creator" content="ecoinspection.in/signup"/>

            {/* <!-- Twitter summary card with large image must be at least 280x150px --/> */}
            <meta name="twitter:image:src" content="https://cdn1.droom.in/imgusr/droomEco/content/scooter-inspection-img.png"/>
            <meta name="twitter:app:name:googleplay" content="ECO Inspection"/>
            <meta name="twitter:app:url:googleplay" content="https://play.google.com/store/apps/details?id=in.droom.eco"/>

            {/* <!-- Open Graph data --/> */}
            <meta property="og:title" content="Create an account to be a ECO Certified Technician." />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="ecoinspection.in/signup" /> 
            <meta property="og:image" content="https://cdn1.droom.in/imgusr/droomEco/content/scooter-inspection-img.png" />
            <meta property="og:description" content="Create an account to be a ECO Certified Technician." />
            <meta property="og:site_name" content="@ecoinspection" />
            <meta property="og:locale" content="en_US" />

            {/* <!-- Schema.org markup for Google+ --/> */}
            <meta itemprop="name" content="Create an account to be a ECO Certified Technician."/>
            <meta itemprop="description" content="Create an account to be a ECO Certified Technician."/>
            <meta itemprop="image" content="https://cdn1.droom.in/imgusr/droomEco/content/scooter-inspection-img.png"/>

            <link rel="canonical" href="https://ecoinspection.in/signup"></link>  
        </Helmet>
        
    );
}

export default TechMetaTags;