import { configureStore as baseConfigureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import { bucketSlice } from './FormRedux/reducers/bucketSlice';
import { formSlice } from './FormRedux/reducers/formSlice';
import { orderSlice } from './FormRedux/reducers/orderSlice';
import { stepSlice } from './FormRedux/reducers/stepSlice';
import { aiBasedSlice } from './AIRedux/aiBasedSlice';
import { aiServiceDataSlice } from './AIRedux/aiServiceDataSlice';
const reducer = combineReducers({
    form: formSlice.reducer,
    step: stepSlice.reducer,
    buckets: bucketSlice.reducer,
    order: orderSlice.reducer,
    aiBased:aiBasedSlice.reducer,
    aiServiceData:aiServiceDataSlice.reducer
});

const store = baseConfigureStore({
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
    reducer
});

export default store;
