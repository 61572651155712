import { Helmet } from "react-helmet";
const MetaTags = () => {
    return (
        
        <Helmet>
            <title>Scooter Mechanic near me, Online Scooter Inspection Services in India</title>
            <meta name="description" content="Scooter Inspection Services - Check online local scooter mechanic shop near me. Use our inspection services before buying and selling any used scooter or scooty."/>
            {/* <!-- Twitter Card data --/> */}
            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:site" content="@ecoinspection"/>
            <meta name="twitter:title" content="Scooter Inspection, Online Used Scooter Inspection Services in India"/>
            <meta name="twitter:description" content="Used Scooter Inspection- A central repository for information on scooter inspection, emissions testing, door to door, pre-purchase scooter, inspection stations and inspectors."/>
            <meta name="twitter:creator" content="ecoinspection.in/scooter-inspection"/>

            {/* <!-- Twitter summary card with large image must be at least 280x150px --/> */}
            <meta name="twitter:image:src" content="https://cdn1.droom.in/imgusr/droomEco/content/scooter-inspection-img.png"/>
            <meta name="twitter:app:name:googleplay" content="ECO Inspection"/>
            <meta name="twitter:app:url:googleplay" content="https://play.google.com/store/apps/details?id=in.droom.eco"/>

            {/* <!-- Open Graph data --/> */}
            <meta property="og:title" content="Scooter Inspection, Online Used Scooter Inspection Services in India" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="ecoinspection.in/scooter-inspection" /> 
            <meta property="og:image" content="https://cdn1.droom.in/imgusr/droomEco/content/scooter-inspection-img.png" />
            <meta property="og:description" content="Used Scooter Inspection- A central repository for information on scooter inspection, emissions testing, door to door, pre-purchase scooter, inspection stations and inspectors." />
            <meta property="og:site_name" content="@ecoinspection" />
            <meta property="og:locale" content="en_US" />

            {/* <!-- Schema.org markup for Google+ --/> */}
            <meta itemprop="name" content="Scooter Mechanic near me, Online Scooter Inspection Services in India"/>
            <meta itemprop="description" content="Scooter Inspection Services - Check online local scooter mechanic shop near me. Use our inspection services before buying and selling any used scooter or scooty."/>
            <meta itemprop="image" content="https://cdn1.droom.in/imgusr/droomEco/content/scooter-inspection-img.png"/>

            <link rel="canonical" href="https://ecoinspection.in/scooter-inspection"></link>  
        </Helmet>
        
    );
}

export default MetaTags;