const WhyEcoContent = () => {
    return (
        <div className="tab-content why-eco-tab-content p-md-4 p-1 ps-md-0" id="WhyEco">
            <div className="tab-pane fade show active" id="for-buyer" role="tabpanel" aria-labelledby="for-buyer-tab">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col ps-md-0">
                            <figure className="figure">
                                <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomresponsive/img/content/why-eco-img.png"} className="figure-img img-fluid" alt="Why ECO | Droom" />
                            </figure>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <ul className="d-flex flex-column list-unstyled details mt-md-5 pn-regular">
                                <li className="my-md-3 my-2">
                                    <div className="bg-white p-10 rounder-3 shadow icon-block d-inline-block align-middle text-center">
                                        <span className="sprite transparency mt-3 ms-1"></span>
                                    </div>
                                    <div className="p-10 text-gray-light fs-14 d-inline-block align-middle ms-3">Complete Transparency and Visibility - Exhaustive Checklist Points.</div>
                                </li>
                                <li className="my-md-3 my-2">
                                    <div className="d-bg-white p-10 rounder-3 shadow icon-block d-inline-block align-middle text-center">
                                        <span className="sprite eco-technician m-auto mt-3"></span>
                                    </div>
                                    <div className="p-10 text-gray-light fs-14 d-inline-block align-middle ms-3">Inspected by Handpicked Best Certified Technicians</div>
                                </li>
                                <li className="my-md-3 my-2">
                                    <div className="d-bg-white p-10 rounder-3 shadow icon-block d-inline-block align-middle text-center">
                                        <span className="sprite setting m-auto mt-3"></span>
                                    </div>
                                    <div className="p-10 text-gray-light fs-14 d-inline-block align-middle ms-3">Get Faulty Parts Repair Estimate - Ensures Optimal Pricing While Buying/Selling Used Vehicle</div>
                                </li>
                            </ul>
                            <div className="d-table m-auto mt-md-5 mb-5 mt-4">
                                <a href={process.env.REACT_APP_DROOM_WEBSITE_URL + '/inspection'} className="btn btn-primary ps-5 pe-5 p-2 text-white text-capitalize cp-medium">Book Inspection</a>
                            </div>
                        </div>
                        <div className="col-1"></div>
                    </div>
                </div>
            </div>
            <div className="tab-pane fade" id="for-technician" role="tabpanel" aria-labelledby="for-technician-tab">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <figure className="figure">
                                <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomresponsive/img/content/auto-inspection.jpg"} className="figure-img img-fluid" alt="Why ECO | Droom" />
                            </figure>
                        </div>
                        <div className="col-md-5 my-2">
                            <ul className="d-flex flex-wrap technician-list text-center list-unstyled details mt-md-5 mt-1 pn-regular">
                                <li className="my-md-3 my-2">
                                    <div className="bg-white p-10 rounder-3 shadow icon-block d-inline-block align-middle text-center">
                                        <span className="sprite join-eco m-auto mt-3"></span>
                                    </div>
                                    <div className="p-10 text-gray-light fs-14 d-inline-block align-middle ms-3 mt-3">Join ECO, Perform Inspection and Earn</div>
                                </li>
                                <li className="my-md-3 my-2">
                                    <div className="d-bg-white p-10 rounder-3 shadow icon-block d-inline-block align-middle text-center">
                                        <span className="sprite flexible m-auto mt-3"></span>
                                    </div>
                                    <div className="p-10 text-gray-light fs-14 d-inline-block align-middle ms-3 mt-3">Flexible Work at your own convenience</div>
                                </li>
                                <li className="my-md-3 my-2">
                                    <div className="d-bg-white p-10 rounder-3 shadow icon-block d-inline-block align-middle text-center">
                                        <span className="sprite boss m-auto mt-3"></span>
                                    </div>
                                    <div className="p-10 text-gray-light fs-14 d-inline-block align-middle ms-3 mt-3">Be your own Boss</div>
                                </li>
                                <li className="my-md-3 my-2">
                                    <div className="d-bg-white p-10 rounder-3 shadow icon-block d-inline-block align-middle text-center">
                                        <span className="sprite training m-auto mt-3"></span>
                                    </div>
                                    <div className="p-10 text-gray-light fs-14 d-inline-block align-middle ms-3 mt-3">Get trained using ECO tutorials</div>
                                </li>
                            </ul>
                            <div className="d-table m-auto mt-md-5 mb-5 mt-4">
                                <a href="/signup" className="btn btn-primary ps-5 pe-5 p-2 text-white text-capitalize cp-medium">Register as Technician</a>
                            </div>
                        </div>
                        <div className="col-1"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyEcoContent;