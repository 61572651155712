import InnerPageBanner from '../InnerPageBanner';
import { useEffect } from "react";
import MetaTags from './MetaTags';
const ScooterInspection = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (<>
    <MetaTags />
        <InnerPageBanner heading={'Get A Used Scooter Inspection With ECO'} bgImage={'scooter-inspection-bg'} />
        <div className="service-section container-fluid mb-3 mt-3">
            <div className="row mb-md-4">
                <div className="col-sm-12 mb-4 text-center">
                    <a href={process.env.REACT_APP_DROOM_WEBSITE_URL + '/inspection'} className="btn btn-primary px-4 py-2 text-capitalize">Book Inspection</a>
                </div>
                <div className="col-sm-7 col-md-7 offset-md-1">
                    <h2 className="fs-4 text-gray cp-medium">SCOOTER INSPECTION SERVICE AND <br />ITS IMPORTANCE</h2>
                    <div className="fs-5 mt-3 mb-2 text-gray">Why do you need a Scooter Mechanic?</div>
                    <p className="pn-regular fs-6">Do you want to know more about your scooter’s condition? Or, are you looking to buy a used scooter, but you are not sure about its actual condition? While planning for a pre-owned scooter purchase or selling your own scooter that you have already gone through, it is recommended that you get it checked by a professional technician. It will be always better if you do not miss any chance and get the used scooter inspection done from time to time. The inspection report will reveal whether the condition of the scooter is excellent, good, fair or bad. For a proper inspection, a professional scooter mechanic is required.</p>
                </div>
                <div className="col-sm-5 col-md-4 pe-md-0">
                    <div className="d-block">
                        <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomresponsive/img/content/scooter-inspection-img.png"} alt="Scooter inspection" className="w-100" />
                    </div>
                </div>
            </div>
            <div className="row mb-md-4">
                <div className="col-sm-5 col-md-3 order-1 order-md-0 offset-md-1">
                    <div className="d-block">
                        <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomresponsive/img/content/scooter-service.png"} alt="Scooter inspection" className="img-fluid" />
                    </div>
                </div>
                <div className="col-sm-7 order-0 order-md-1 col-md-7">
                    <div className="fs-5 mt-3 mb-2 text-gray">Why ECO?</div>
                    <p className="pn-regular fs-6">Forget all the worries and take ECO inspection services from Droom, India’s first and only marketplace for automobiles and vehicle related services. ECO offers used vehicle inspection that includes used car, used bike and used scooter inspection at very affordable rate. So, if you are looking for a cheap scooter mechanic, then ECO will be the best stop. Forget about the troublesome process of searching a technician or mechanic and taking the scooter to a workshop. Keep aside all the hassles of getting your scooter an overall inspection and opt for our ECO inspection services. Book ECO scooter inspection service online and get to know if the used scooter you are going to buy has got any damage or other issues.</p>
                </div>
                <div className="col-sm-12 order-2 col-md-10 offset-md-1">
                    <div className="fs-5 mt-3 mb-2 text-gray">Where to find a Scooter Mechanic near me?</div>
                    <p className="pn-regular fs-6">To make the whole process easier for the users, ECO provides the services at your doorstep when you book a scooter inspection online request. It takes lots of effort to find a good scooter mechanic near me and then taking the scooter to their workshop. With ECO as your service provider, you will get to meet experienced technicians who will come to your doorstep at the time you are going to be comfortable with. This way, it is possible to get a proper scooter inspection near me at the ease of home or office at the most convenient time. Used scooter inspection is very important for every scooter that is running on road.</p>
                </div>
                <div className="col-sm-12 order-3 col-md-10 offset-md-1">
                    <div className="fs-5 mt-3 mb-2 text-gray">What should a Scooter Inspection Checklist Include?</div>
                    <p className="pn-regular fs-6">Scooter check up from ECO helps to identify any kind of problem with your scooter or the scooter you are going to purchase. It will see if any of the parts of the vehicle are malfunctioning or if there is any damage. Also, it will look for issues with the whole body of the scooter focusing on the tires and wheels (both front and rear), L-lights & Electrics, Vehicle C-Control (both front and rear), vehicle stand, body paint, damage caused by accident, rust, etc.</p>
                </div>
            </div>
        </div>
    </>);
}
export default ScooterInspection;