import React, { useEffect } from 'react';
import Component from '../Components/Component';
import TextInput from '../Elements/TextInput';
import DateInput from '../Elements//DateInput';
import RadioInput from '../Elements/RadioInput';
import Dropdown from '../Elements/Dropdown';
import ImageView from '../Elements/ImageView';
import CommonPhotoUpload from '../Components/CommonPhotoUpload';
import { Card } from 'react-bootstrap';

const uiMapper = {
    tb: TextInput,
    ui: CommonPhotoUpload,
    rbg: RadioInput,
    dt: DateInput,
    dd: Dropdown,
    iv: ImageView
};

const Parameter = ({ components, paramId, paramName, bucketId, screenId }) => {
    const [compList, setCompList] = React.useState(components);
    useEffect(() => {
        setCompList(components);
    }, [components]);

    return (
        <Card className="p-3 shadow-sm">
            {compList?.map((component, index) => {
                const Element = uiMapper[component.element_type];
                const isVisible = parseInt(component.component_options?.is_visible || 0);

                if (!isVisible) {
                    return;
                }

                return (
                    <React.Fragment key={index}>
                        <div style={{ color: 'black' }}>{component?.name}</div>
                        <Component
                            key={index}
                            elements={
                                <Element
                                    componentOptions={component.component_options}
                                    componentDetails={{
                                        components,
                                        setCompList,
                                        parameterId: paramId,
                                        parameterName: paramName,
                                        bucketId,
                                        screenId,
                                        componentIndex: index,
                                        componentId: component?.id,
                                        componentName: component?.code,
                                        type: component?.element_type
                                    }}
                                />
                            }
                        />
                    </React.Fragment>
                );
            })}
        </Card>
    );
};

export default Parameter;
