import React from 'react';
import { Button } from 'react-bootstrap';

const SubmitButton = ({ onClick, children, className }) => {
    return (
        <Button className={className} variant="primary" type="submit" onClick={onClick}>
            {children}
        </Button>
    );
};

export default SubmitButton;
