import { useEffect } from "react";
import SignUp from "./SingUp";
import Testimonials from "../home/Testimonials";
import EcoNews from "../home/EcoNews";
import Faqs from "../home/Faqs";
import InnerPageBanner from '../InnerPageBanner';

const Main = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <>
            <InnerPageBanner heading={'Sign Up With ECO'} bgImage={'signup-bg'} />
            <SignUp />
            <Testimonials />
            <EcoNews />
            <Faqs />
        </>
    );
}
export default Main;