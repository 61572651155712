import React from 'react';
import Banner from './Banner';
import Home from './Home';
import '../css/cerapro.css';
import '../css/Dashboard.css';

const Dashboard = () => {
    return (
        <div className="dashboard">
            <Banner />
            <Home />
        </div>
    );
};

export default Dashboard;
