import React, { useState, useRef, useEffect } from 'react';
import { Modal, Form } from 'react-bootstrap';
import ResendOTP from '../Common/ResendOTP';
const OTPModal = ({ show, handleClose, handleVerify, number, submitHandler }) => {
    const [otp1, setOTP1] = useState('');
    const [otp2, setOTP2] = useState('');
    const [otp3, setOTP3] = useState('');
    const [otp4, setOTP4] = useState('');
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
    const handleChange = (e, inputNum) => {
        const value = e.target.value;
        switch (inputNum) {
            case 1:
                setOTP1(value);
                if (value !== '') inputRefs[1].current.focus();
                break;
            case 2:
                setOTP2(value);
                if (value !== '') inputRefs[2].current.focus();
                break;
            case 3:
                setOTP3(value);
                if (value !== '') inputRefs[3].current.focus();
                break;
            case 4:
                if (value.length === 1) {
                    setOTP4(value);
                }
                break;
            default:
                break;
        }
    };
    useEffect(() => {
        if (show) {
            inputRefs[0].current.focus();
        }
        const submit = async () => {
            if (otp4) {
                await handleSubmit();
            }
        };
        submit();
    }, [show, otp4]);

    const handleSubmit = async () => {
        const fullOTP = otp1 + otp2 + otp3 + otp4;
        await handleVerify(fullOTP);
        setOTP1('');
        setOTP2('');
        setOTP3('');
        setOTP4('');
    };

    return (
        <Modal show={true} onHide={handleClose} centered>
            <Modal.Header closeButton className="border-0"></Modal.Header>
            <Modal.Body>
                <Modal.Title className="text-center mb-3">We have sent you an OTP</Modal.Title>
                <div>Enter the 4 digit OTP sent on</div>
                <div>
                    <span>{number}</span> to proceed
                </div>
                <Form>
                    <Form.Group controlId="formOTP">
                        <div className="d-flex justify-content-between gap-3 text-center my-3 custom-opt-box">
                            <Form.Control
                                type="number"
                                className="text-center py-4"
                                maxLength="1"
                                value={otp1}
                                onChange={e => handleChange(e, 1)}
                                ref={inputRefs[0]}
                            />
                            <Form.Control
                                type="number"
                                className="text-center py-4"
                                maxLength="1"
                                value={otp2}
                                onChange={e => handleChange(e, 2)}
                                ref={inputRefs[1]}
                            />
                            <Form.Control
                                type="number"
                                className="text-center py-4"
                                maxLength="1"
                                value={otp3}
                                onChange={e => handleChange(e, 3)}
                                ref={inputRefs[2]}
                            />
                            <Form.Control
                                type="number"
                                className="text-center py-4"
                                maxLength="1"
                                value={otp4}
                                onChange={e => handleChange(e, 4)}
                                ref={inputRefs[3]}
                            />
                        </div>
                    </Form.Group>
                </Form>
                <div>Didn't receive an OTP?</div>
                <div className="resend-otp">
                    <ResendOTP submitHandler={submitHandler} />
                </div>
            </Modal.Body>
        </Modal>
    );
};
export default OTPModal;
