import axios from 'axios';
import { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BucketGuidelines from './BucketGuidelines';
import SubHeader from '@pages/SelfInspection/Common/SubHeader';
import Loader from '@pages/SelfInspection/Common/Loader';

const AIBuckets = () => {
    const [loading, setLoading] = useState(true);
    const [bucketList, setBucketList] = useState([]);
    const [checkGuideline, setCheckGuideline] = useState(true);
    const { orderStatus } = useSelector(state => state.order);
    const [selectBucketOnClick, setselectBucketOnClick] = useState(null);

    const params = useParams();
    const history = useHistory();

    useEffect(() => {
        axios.get(`/orders/${params.orderId}/json-ai/buckets`).then(res => {
            if (res.data.StatusCode == 1) {
                setCheckGuideline(!res.data.Result.some(item => item.remaining == 1));
                setBucketList(res.data.Result);
                setLoading(false);
            }
        });
    }, [params.orderId]);

    if (loading) {
        return <Loader />;
    }

    const GetFilledStatus = ({ bucket }) => {
        if (bucket.remaining > 0) {
            return (
                <img
                    src="https://cdn1.droom.in/photos/images/eco/dashboard/orange1.svg"
                    className="basket-status-icon"
                    alt="Basket Status Icon"
                />
            );
        }

        if (bucket.remaining == 0) {
            return (
                <img
                    src="https://cdn1.droom.in/photos/images/eco/dashboard/tick.svg"
                    className="basket-status-icon"
                    alt="Basket Status Icon"
                />
            );
        }
    };
    const allCompleted = bucketList.every(item => item.remaining === 0);
    const handleFinishClick = () => {
        axios.post(`/orders/${params.orderId}/inspection-ai/complete`).then(() => history.push('../completed'));
    };

    const handleCardClick = (bucket) => {
        if (bucket.remaining === 0 || bucket.bucketGuidelines.length === 0) {
            history.push(`ai/buckets/${bucket.id}`);
        } 
        else {
            setselectBucketOnClick(bucket);
        }
    }
    
    return (
        <>
            <SubHeader showPageCount={false} />
            <div className="p-3">
                {!selectBucketOnClick? (
                    <>
                        {bucketList?.map(bucket => (
                            <Card
                                key={bucket.id}
                                onClick={() => handleCardClick(bucket)}
                                className="my-2 rounded shadow-sm p-3 basket d-flex align-items-center justify-content-between gap-3 flex-row"
                            >
                                <div className="basket-content fs-5">{bucket.displayName}</div>
                                <div className="icon-el">
                                    <GetFilledStatus bucket={bucket} />
                                </div>
                            </Card>
                        ))}

                        {allCompleted && (
                            <div className="text-center mt-3">
                                <button className="btn btn-success w-100" onClick={handleFinishClick}>
                                    Finish
                                </button>
                            </div>
                        )}
                    </>
                ) : (
                    <BucketGuidelines guidelines={selectBucketOnClick.bucketGuidelines} bucket={selectBucketOnClick} />
                )}
            </div>
        </>
    );
};

export default AIBuckets;
