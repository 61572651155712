import { Link } from "react-router-dom";
import { useEffect } from "react";
import InnerPageBanner from './InnerPageBanner';
import { Helmet } from "react-helmet";
const TermsConditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Helmet><title>Terms & Conditions | ECO</title>
            </Helmet>
            <InnerPageBanner heading={'Terms & Conditions'} />
            <div className="service-section container mb-3">
                <div className="row">
                    <div className="col-sm-12">
                        <p>
                            <strong>
                                Last updated on: 6<sup>th</sup> September 2023
                            </strong>
                        </p>
                        <h3>TERMS OF USE</h3>
                        <p>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures..</p>
                        <p>These terms of use (hereinafter “Terms of Use”) are published in accordance with the provisions of Rule 3(1) of the Information Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of https://ecoinspection.in/ website.</p>
                        <p>Ecoinspection.in is owned/ licensed and operated by Droom Technology Limited (formerly known as Droom Technology  Private Limited), a company incorporated under the Companies Act, 2013, having its registered office at: 90/31B, First Floor, Malviya Nagar, New Delhi – 110017 (hereinafter, individually and/ or collectively referred to as “Droom/ Company”). Droom provides access to a variety of services, including but not limited to access to the https://ecoinspection.in/ website, mobile site and mobile application (hereinafter individually and collectively referred to as “Website”). These Terms of Use constitute a binding legal agreement between users and the Website. </p>
                        <p>For the purpose of these Terms of Use, wherever the context so requires "User", "You" or “Your” shall mean any natural or legal person who is a user/ visitor of the Website availing the services, raising queries and seeking information and/ or has agreed to become user on the Website by providing registration data while registering on the Website as registered User using the computer systems. Droom allows the User to surf the Website and / or avail the services by registering on the Website. The term "We", "Us", "Our" shall mean Droom.</p>
                        <p>Your use of the Website, services and tools are governed by the following Terms of Use as applicable to the Website including the applicable policies which are incorporated herein by way of reference. If You transact on the Website, You shall be subject to the policies that are applicable to the Website for such transactions. By mere use of the Website, You shall be contracting with Droom and this Terms of Use including the policies constitute Your binding obligations with Droom.</p>
                        <p>When You use any of the services provided by Us through the Website, including but not limited to the information relating to the vehicle such as No. of owners, Vehicle Age, Accidental History/ No. of accidents, results of inspection of each Segment, Individual checkpoint score, Estimated Cost for faulty parts/ repairs and other services (hereinafter referred to as “Services”) provided to You / User on and through this Website, You will be subject to the rules, guidelines, policies, terms and conditions applicable to such Services, and they shall be deemed to be incorporated into these Terms of Use and shall be considered as part and parcel of these Terms of Use. </p>
                        <p>We reserve the right, at Our sole discretion, to change, modify, add or remove portions of these Terms of Use, at any time without any prior written notice to You. It is Your responsibility to review these Terms of Use periodically for updates / changes. Your continued use of the Website following the posting of changes will mean that You accept and agree to the revisions. As long as You comply with these Terms of Use, We grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the Website.</p>
                        <p>BY ACCESSING, BROWSING OR OTHERWISE USING THE WEBSITE INDICATES YOUR ACCEPTANCE TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.</p>
                        <p>By impliedly or expressly accepting Droom’s and this Website’s Terms of Use, You also accept and agree to be bound by Droom’s and this Website’s policies (including but not limited to Privacy Policy available on&nbsp;https://ecoinspection.in/privacy-policy) as amended from time to time. We shall not be obliged to notify You, irrespective of the fact that You are registered or not, of any modifications made to the Terms of Use.</p>

                        <h3>MEMBERSHIP ELIGIBILITY</h3>
                        <p>Use of the Website is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to use the Website. If You are a minor i.e. under the age of 18 years, You shall not register as a User of the Website and shall not transact on or use the Website. As a minor, if You wish to use or transact the Website, such use or transaction may be made by Your legal guardian or parents on the Website. Droom reserves the right to terminate Your membership and / or refuse to provide You with access to the Website if it is brought to Droom's notice or if it is discovered that You are under the age of 18 years or otherwise legally disqualified or incompetent to enter into contract as per the Indian Contract Act or any other laws applicable in India. Furthermore, Droom has the right at its sole discretion to refuse to provide access to or terminate the access of any person(s) whatsoever with or without notice.</p>
                        <h3>YOUR ACCOUNT AND REGISTRATION OBLIGATIONS</h3>
                        <p>If You use the Website, You shall be responsible for maintaining the confidentiality of Your information including display name and password (if applicable) and your other personal information.  You shall be responsible for all activities undertaken by You, including but not limited to use of the Website for availing the services through this Website that occur under Your display name and password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with this Terms of Use, We shall have the right to indefinitely suspend or terminate or block access of Your membership on the Website and refuse to provide You with access to the Website.</p>

                        <h3>COMMUNICATIONS</h3>
                        <p>When You use the Website, or send emails or other data, information or communication to us and provide Your information required for availing the services, You agree and understand that You are communicating with us through electronic records, by telephonic calls, SMS and You consent to receive communications via electronic records, telephone calls and SMSs from Us periodically and as and when required. We may communicate with You by email or by such other mode of communication, electronic or otherwise.</p>

                        <h3>PLATFORM FOR TRANSACTION, COMMUNICATION AND INTERACTION</h3>
                        <p>The Website is a platform that Users utilize to inter alia get required information, transact and share their reviews about the automobiles. Droom and this Website shall in no manner be held responsible for any opinion expressed, review made by any of the User(s) on this Website and/ or is not and cannot be a party to or control in any manner any transactions and actions of the Website's Users</p>


                        <h3>HENCE FORWARD:</h3>
                        <ol className="decimal">
                            <li>Vehicle inspections are performed by independent, third-party mechanics and/ or auto repair shops. Droom does not own, operate, or control any repair shop or mechanic designated as a Droom certified inspection location, station or shop or employ any third-party mechanics and, as such, Droom does not guarantee the inspection results.
                            </li>
                            <li>Droom does not implicitly or explicitly support or endorse the sale or purchase of any products or services on the Website. The inspection report is meant to cover certain items, but not all mechanical components of a vehicle. The inspection reports available for the vehicles may not be updated with the latest information about the vehicles and items that may appear acceptable now, may need work or attention shortly after the inspection is completed. Droom is not liable or responsible for any damage to your vehicle caused during the inspection or while your vehicle is in the possession of the mechanic or auto repair shop.</li>
                            <li>Droom provides guidelines on how to perform the 5-segment, 121 points plus mechanical inspection, but other than this, Droom or the Website has no control or authority over such mechanics. Droom implements procedures to seek out quality mechanics, but does not guarantee the work of any mechanic, as each mechanic operates separately and independently without any control or direct supervision from Droom. </li>
                            <li>Droom is not responsible for any non-performance or breach of any contract entered between any Users and third parties in any manner. Droom cannot and does not, guarantee the correctness of the information available on this Website and shall not and is not required to mediate or resolve any dispute or disagreement between the Users and any third parties.</li>
                            <li>At no time shall Droom hold any right, title or interest over the products / automobiles, nor shall Droom have any obligations or liabilities in respect of such contract.</li>
                            <li>You / User of the Website releases and indemnifies Droom and/or any of its officers, assigns and representatives from any cost, damage, liability or other consequence of any of the actions of the Users of the Website and specifically waive any claims that You / User may have in this behalf under any applicable law(s). Notwithstanding the indemnification mentioned hereinabove, its reasonable efforts in that behalf, Droom cannot take responsibility or control of the information provided by other Users, which is made available on the Website. You may find other User's information to be offensive, harmful, inconsistent, inaccurate, or deceptive. Please use caution and practice safe trading when using the Website.</li>
                            <li>Please note that there could be risks in dealing with underage persons or people acting under false pretence and the User is expected to conduct due diligence in this regard.</li>
                        </ol>
                        <h3>FEE POLICY</h3>
                        <p>Membership on the Website is free. Droom does not charge any fee for browsing on the Website. However, Droom reserves the right to change its Fee Policy from time to time and at its sole discretion. In particular, Droom may at its sole discretion introduce new services and modify some or all of the existing services offered on the Website. In such an event, Droom reserves the right to introduce fees for the new services offered or amend/introduce fees for existing services, as the case may be. Changes to the Fee Policy shall be posted on the Website and such changes shall automatically become effective immediately after they are posted on the Website. Unless otherwise stated, all fees shall be quoted in Indian Rupees. You / User shall be solely responsible for compliance of all applicable laws including those in India for making payments to Droom, wherever applicable.</p>
                        <h3>SERVICES PAYMENT</h3>
                        <p>While availing any of the payment method/s available on the Website, We will not be responsible or assume any liability, whatsoever in respect of any loss or damage arising directly or indirectly to You due to:</p>
                        <ol className="disc"><li>
                            Lack of authorization for any transaction/s, or
                        </li>
                            <li>
                                Exceeding the preset limit mutually agreed by You and between Bank/s, or
                            </li>
                            <li>
                                Any payment issues arising out of the transaction, or
                            </li>
                            <li>
                                Decline of transaction for any other reason/s.
                            </li>
                        </ol>
                        <p>All payments made against the purchases/Services on Website by You shall compulsorily be in Indian Rupees acceptable in the Republic of India. Droom/ Website will not facilitate transaction with respect to any other form of currency with respect to the purchases made on Website.</p>
                        <h3>USE OF THE WEBSITE</h3>
                        <p>You / User agree, undertake and confirm that Your use of Website shall be strictly governed by the following binding principles:</p>
                        <ol className="decimal">
                            <li>You shall not host, display, upload, modify, publish, transmit, update or share any information which:
                                <ol className="lower-alpha">
                                    <li>Belongs to another person and to which You does not have any right to;</li>
                                    <li>Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</li>
                                    <li>Is misleading in any way;</li>
                                    <li>Is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual;</li>
                                    <li>Harasses or advocates harassment of another person;</li>
                                    <li>Involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";</li>
                                    <li>Promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libellous;</li>
                                    <li>Infringes upon or violates any third party's rights [including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity];</li>
                                    <li>Promotes an illegal or unauthorized copy of another person's copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or links to pirated music files;</li>
                                    <li>Contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page);</li>
                                    <li>Provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone;</li>
                                    <li>Provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses;</li>
                                    <li>Contains video, photographs, or images of another person (with a minor or an adult);</li>
                                    <li>Tries to gain unauthorized access or exceeds the scope of authorized access to the Website or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Website or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;</li>
                                    <li>Engages in commercial activities and/or sales without Our prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of "virtual" products related to the Website. Throughout these Terms of Use, Droom's prior written consent means a communication coming from Droom's Legal Department, specifically in response to Your request, and specifically addressing the activity or conduct for which You seek authorization;</li>
                                    <li>Solicits gambling or engages in any gambling activity which We, in Our sole discretion, believes is or could be construed as being illegal;</li>
                                    <li>Interferes with another User's use and enjoyment of the Website or any other individual's User and enjoyment of similar services;</li>
                                    <li>Refers to any website or URL that, in Our sole discretion, contains material that is inappropriate for the Website or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms of Use;</li>
                                    <li>Harm minors in any way;</li>
                                    <li>Infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;</li>
                                    <li>Violates any law for the time being in force;</li>
                                    <li>Deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing immature;</li>
                                    <li>Impersonate another person;</li>
                                    <li>Contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancel bots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;</li>
                                    <li>Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.
                                    </li>
                                </ol>
                            </li>
                            <li>The User of this Website is required to ensure and undertakes that the information provided on this Website or any actions of the User(s) on this Website shall:
                                <ol className="lower-alpha">
                                    <li>not be false, inaccurate or misleading;</li>
                                    <li>not, directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force;</li>
                                    <li>not create liability for Us or cause Us to lose (in whole or in part) the services of Our internet service provider ("ISPs") or other suppliers;</li>
                                    <li>not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or any Content, or in any way reproduce or circumvent the navigational structure or presentation of the Website or any Content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Website. We reserve Our right to bar any such activity;</li>
                                    <li>not attempt to gain unauthorized access to any portion or feature of the Website, or any other systems or networks connected to the Website or to any server, computer, network, or to any of the services offered on or through the Website, by hacking, password "mining" or any other illegitimate means;</li>
                                    <li>not probe, scan or test the vulnerability of the Website or any network connected to the Website nor breach the security or authentication measures on the Website or any network connected to the Website. You may not reverse look-up, trace or seek to trace any information on any other User of or visitor to Website, or any other customer, including any account on the Website not owned by You, to its source, or exploit the Website or any service or information made available or offered by or through the Website, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than Your own information, as provided for by the Website;</li>
                                    <li>not make any negative, denigrating or defamatory statement(s) or comment(s) about Us or the brand name or domain name used by Us including the terms of Droom, or otherwise engage in any conduct or action that might tarnish the image or reputation, of Droom or sellers on platform or otherwise tarnish or dilute any Droom's trade or service marks, trade name and/or goodwill associated with such trade or service marks, trade name as may be owned or used by us. You agree that You will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Website or Droom’s systems or networks, or any systems or networks connected to Droom;</li>
                                    <li>not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Website or any transaction being conducted on the Website, or with any other person's use of the Website;</li>
                                    <li> not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal You send to Us on or through the Website or any service offered on or through the Website. You may not pretend that You are, or that You represent, someone else, or impersonate any other individual or entity;</li>
                                     <li> not use the Website or any content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of Droom and / or others;</li>
                                     <li> at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and rules thereunder as applicable and as amended from time to time and also all applicable Domestic laws, rules and regulations applicable regarding Your use of Our service. You shall not engage in any transaction of an item or service, which is prohibited by the provisions of any applicable law including exchange control laws or regulations for the time being in force.</li>
                                    <li> act in a bonafide manner to enable Us to use the information You supply Us with, so that We are not violating any rights You might have in Your Information, You agree to grant Us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights You have in Your Information, in any media now known or not currently known, with respect to Your Information. We will only use Your information in accordance with the Terms of Use and Privacy Policy applicable to use of the Website;
                                    </li>
                                </ol>
                            </li>
                            <li>You shall not engage in advertising to, or solicitation of, other Users of the Website to buy or sell any products or services, including, but not limited to, products or services related to that being displayed on the Website or related to Us. You may not transmit any chain letters or unsolicited commercial or junk email to other Users via the Website. It shall be a violation of these Terms of Use to use any information obtained from the Website in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than Us without Our prior explicit consent. In order to protect Our Users from such advertising or solicitation, We reserve the right to restrict the number of messages or emails which an User may send to other Users in any 24-hour period which We deem appropriate in our sole discretion. You understand and agree that, we have the right at all times to disclose any information (including the identity of the persons providing information or materials on the Website) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena. In addition, We can (and You hereby expressly authorize Us to) disclose any information about You to law enforcement or other government officials, as we, in Our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.</li>
                            <li>We reserve the right, but have no obligation, to monitor the materials posted on the Website. Droom shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any applicable law or either the spirit or letter of these Terms of Use. <br />
                                Notwithstanding this right, YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON THE WEBSITE AND IN YOUR PRIVATE MESSAGES. Please be advised that such content posted does not necessarily reflect Droom’s views. In no event, shall Droom assume or have any responsibility or liability for any content posted or for any claims, damages or losses resulting from use of content and/or appearance of content on the Website. You hereby represent and warrant that You have all necessary rights in and to all content which You provide and all information it contains and that such content shall not infringe any proprietary or other rights of third parties or contain any libellous, tortious, or otherwise unlawful information.
                            </li>
                            <li>Your correspondence or business dealings with, or participation in promotions of, advertisers found on or through the Website, including payment and delivery of related products or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between You and such advertiser. We shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of such advertisers on the Website.</li>
                            <li>It is possible that other users (including unauthorized users or "hackers") may post or transmit offensive or obscene materials on the Website and that You may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about You due to Your use of the Website, and that the recipient may use such information to harass or injure You. We do not approve of such unauthorized uses, but by using the Website You acknowledge and agree that We are not responsible for the use of any personal information that You publicly disclose or share with others on the Website. Please carefully select the type of information that You publicly disclose or share with others on the Website.</li>
                            <li>Droom shall have all the rights to take necessary action and claim damages that may occur due to Your involvement/participation in any way on Your own or through group/s of people, intentionally or unintentionally in DoS/DDoS (Distributed Denial of Services) and for violation of any other law applicable and in force.</li>
                        </ol>
                        <h3>CONTENTS POSTED ON WEBSITE:</h3>
                        <p><strong>A.)  THIRD PARTY CONTENT:</strong></p>
                        <p>All text, graphics, videos, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music and artwork (collectively, "Content"), other than the ones created, originated and used by the Company is a third-party user generated content and Droom has no control over such third-party user generated content as Droom is merely an intermediary for the purposes of these Terms of Use.</p>
                        <p><strong>B.)  USER GENERATED CONTENT:</strong></p>
                        <p>You shall be responsible for any notes, messages, emails, billboard postings, photos, drawings, profiles, opinions, ideas, images, videos, audio files or other materials or information posted or transmitted to the Website (collectively, "User Content"). Such User Content will become Our property and You grant Us the worldwide, perpetual and transferable rights in such User Content. We shall be entitled to, consistent with Our Privacy Policy as adopted in accordance with applicable law, to use the User Content or any of its elements for any type of use forever, including but not limited to promotional and advertising purposes and in any media whether now, known or hereafter devised, including the creation of derivative works that may include the User Content You provide. You agree that any User Content You post may be used by Us, consistent with Our Privacy Policy and rules of conduct on the Website as mentioned herein, and You are not entitled to any payment or other compensation for such use.</p>
                        <p>Except as expressly provided in these Terms of Use, no part of the Website and no content including but not limited to the third-party Content and/or User Content may be copied, reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed in any way (including "mirroring") to any other computer, server, Website or other medium for publication or distribution or for any commercial enterprise, without Droom's express prior written consent. You may use information on the products and Services purposely made available on the Website for downloading, provided that You (1) do not remove any proprietary notice language in all copies of such documents, (2) use such information only for Your personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media, (3) make no modifications to any such information, and (4) do not make any additional representations or warranties relating to such documents. </p>
                        <p><strong>DISCLAIMER OF WARRANTIES AND LIABILITY</strong></p>
                        <p>This Website, all the materials and products (including but not limited to software) and services, included on or otherwise made available to You through this Website are provided on "as is" and "as available" basis without any representation or warranties, express or implied except otherwise specified in writing. Without prejudice to the forgoing paragraph, Droom does not warrant that:</p>
                        <p>• This Website will be constantly available, or available at all; or</p>
                        <p>• The information on this Website is complete, true, accurate or non-misleading.</p>
                        <p>Droom will not be liable to You in any way or in relation to the contents of, or use of, or otherwise in connection with, the Website. Droom does not warrant that this Website, information, content, materials, product (including software) or Services included on or otherwise made available to You through the Website; their servers; or electronic communication sent from Us are free of viruses or other harmful components. Nothing on Website constitutes, or is meant to constitute, advice of any kind. All the Services / products made available and sold on Website are governed by the applicable laws.</p>
                        <p>You will be required and are expected to enter correct and valid personal details such as Your name, address, phone number and other information as required for use of the Services on this Website. By registering Your details including but not limited to Your phone number with Us, You consent to be contacted by Us via phone calls and/or SMS notifications, in case of any new offers, information, news or other updates. </p>
                        <h3>INDEMNITY</h3>
                        <p>You shall indemnify and hold harmless Droom, its owner, licensee, affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys' fees, made by any third party or penalty imposed due to or arising out of Your breach of this Terms of Use, Privacy Policy and other Policies, or Your violation of any law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party.</p>
                        <h3>APPLICABLE LAW</h3>
                        <p>Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall be exclusively in New Delhi.</p>
                        <h3>JURISDICTIONAL ISSUES IN INDIA ONLY</h3>
                        <p>Unless otherwise specified, the material on the Website is presented solely for the purpose of use in India. Droom make no representation that materials in the Website are appropriate or available for use in other locations/Countries other than India. Those who choose to access this Website from other locations/Countries other than India, do so on their own initiative and risks and Droom is not responsible for the information and Services availed from this Website.</p>
                        <h3>TRADEMARK, COPYRIGHT AND RESTRICTION</h3>
                        <p>This Website is controlled and operated by Droom Technology Limited. All material on this Website, including images, illustrations, audio clips, and video clips, are protected by copyrights, trademarks, and other intellectual property rights. Material on Website is solely for Your personal, and non-commercial use. You must not copy, reproduce, republish, upload, post, transmit or distribute such material in any way, including by email or other electronic means and whether directly or indirectly and You must not assist any other person to do so. Without the prior written consent of the owner/ Droom, any modification of the materials, use of the materials on any other website or networked computer environment or use of the materials for any purpose other than personal, non-commercial use is a violation of the copyrights, trademarks and other proprietary rights, and is strictly prohibited. Any use for which You receive any remuneration, whether in money or otherwise, is a commercial use for the purposes of this clause.</p>
                        <h3>COMPLAINT ALLEGING INFRINGEMENT OF INTELLECTUAL PROPERTY</h3>
                        <p>Droom respects the intellectual property of others. In case You feel that Your intellectual property has been infringed, You can write to Droom at <a href="mailto:support@droom.in">support@droom.in</a> or <a href="mailto:infringement@droom.in">infringement@droom.in</a> or <a href="mailto:legal.group1@droom.in">legal.group@droom.in. </a></p>

                        <p>We request You to please provide the following information in Your complaint:-</p>
                        <p>a. Identification of the material on Our website that is claimed to be infringing or to be the subject of infringing activity.</p>
                        <p>b. Your address, telephone number and e-mail address.</p>
                        <p>c. A statement that You have a good-faith belief that use of the material in the manner complained of is not authorized.</p>

                        <h3>LIMITATION OF LIABILITY</h3>
                        <p>IN NO EVENT SHALL DROOM BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE TERMS OF USE, EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES.</p>
                        <h3>CONTACT US</h3>
                        <p>Please contact Us for any questions or comments (including all inquiries unrelated to intellectual property infringement) regarding this Website by electronic, telephonic or written communication on the addresses provided in the Contact Us section of this Website. </p>

                        <h3>SEVERABILITY</h3>
                        <p>Any provision of these Terms of Use that is found to be prohibited or unenforceable shall be ineffective to the extent of such prohibition or unenforceability, without invalidating the remaining portions hereof.</p>
                        <h3>GRIEVANCE OFFICER</h3>
                        <p>In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:</p>

                        <p><strong>Grievance Redressal Officer:</strong><br /> Mr. Sunil Kumar </p>
                        <p>Plot No. 77-A, Iffco Road, Sector 18, <br />

                            Gurgaon - 122015, Haryana -India.<br />
                            Time: Mon to Sat (10:00 to 19:00)<br />
                            Email: <a href="mailto:support@droom.in">support@droom.in</a>
                        </p>

                    </div>
                </div>
            </div>
        </>
    );
}

export default TermsConditions;