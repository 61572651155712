import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, InputGroup, FormControl, Form } from 'react-bootstrap';
import MetaTags from './MetaTags';
import './../../../resources/css/pages/technician-details/eco-report-v1.css';
import '../../../resources/css/pages/technician-details/eco-report-icomoon.css';

const Main = () => {
    let { id } = useParams();
    const [show, setShow] = useState(true);
    const handleClose = () => {
        setShow(false);
    };
    const handleSubmit = () => {
        setShow(false);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        document.getElementById("eco-loader").style.display = 'flex';
        fetch(process.env.REACT_APP_ECO_API_URL + '/get-report-html?id=' + id, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(response => response.json()).then(data => {
            document.getElementById("eco-loader").style.display = 'none';
            if (data.StatusCode == 1) {
                if (
                    typeof data.Result === 'object' &&
                    data.Result !== null
                ) {
                    console.log('Error in report API.')
                } else {
                    document.getElementById("eco-qr-code").innerHTML += data.Result;
                }
            } else {
                document.getElementById("eco-qr-code").innerHTML += "<div class='text-center'><h4 style='color: red;'>No Data Found!!!<h/4></div>";
            }
        }).catch(e => {
            document.getElementById("eco-loader").style.display = 'none';
            document.getElementById("eco-qr-code").innerHTML += "<div class='text-center'><h4 style='color: red;'>No Data Found!!!<h/4></div>";
            console.log('Caugth error:')
            console.log(e)
            console.log(JSON.stringify(e))
        });
    }, [id]);

    return (
        <>
            <MetaTags />
            <div className="eco-ninja-detail">
                <div className="inner-section" id="eco-qr-code"></div>
            </div>
            {/* <Modal fullscreen={"sm-down"} show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>Please enter your details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Full Name: </Form.Label>
                    <InputGroup className="mb-3">
                        <FormControl aria-label="Full Name" />
                    </InputGroup>
                    <Form.Label>Phone Number: </Form.Label>
                    <InputGroup className="mb-3">
                        <FormControl aria-label="Phone Number" />
                    </InputGroup>
                    <input type="hidden" name="page" value="ecoinspection.in" />
                    <input type="hidden" name="source" value="eco-web" />
                    <input type="hidden" name="vehicleType" value="Car" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="secondary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </>
    );
}
export default Main;
