import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ hasHeader = true, hasFooter = true, menu, children }) => {
    return (
        <>
            <MobileView>
                {hasHeader && <Header menu={menu} />}
                {children}
                {/* {hasFooter && <Footer />} */}
            </MobileView>
            <BrowserView>
                <h1>Please open this url in mobile device to proceed with inspection.</h1>
            </BrowserView>
        </>
    );
};

export default Layout;
