import store from '../redux/store';
import { routes } from '../routes';
import { Helmet } from 'react-helmet';
import helmet from '../static/helmet';
import { Provider } from 'react-redux';
import ErrorBoundary from './ErrorBoundary';
import DefaultLayout from '../layout/Layout';
import { Route, Switch } from 'react-router-dom';
import { FrontloadProvider } from 'react-frontload';
import SelfInspectionRoute from '../routes/SelfInspectionRoute';

const WithHeaderFooter = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                return (
                    <DefaultLayout>
                        <Component {...props} />
                    </DefaultLayout>
                );
            }}
        />
    );
};

export default function App({ frontloadState }) {
    return (
        <>
            <Helmet {...helmet} />
            <Provider store={store}>
                <FrontloadProvider initialState={frontloadState}>
                    <ErrorBoundary>
                        <Switch>
                            {routes.map((item, index) => {
                                if (item.isSelfInspection) {
                                    return <SelfInspectionRoute key={index} {...item} />;
                                }
                                return <WithHeaderFooter key={index} {...item} />;
                            })}
                        </Switch>
                    </ErrorBoundary>
                </FrontloadProvider>
            </Provider>
        </>
    );
}
