import { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import imageCompression from 'browser-image-compression';
import TakePhotos from '../Elements/TakePhotos';
import UploadPhotos from '../Elements/UploadPhotos';
import { updateFiles, resetFiles } from '@redux/FormRedux/reducers/formSlice';

const compressionOptions = {
    maxSizeMB: process.env.COMPRESS_IMAGE_MAX_SIZE || 0.5,
    maxWidthOrHeight: process.env.COMPRESS_IMAGE_MAX_HW || 900,
    useWebWorker: true
};

const ImageView = ({ images, source }) => {
    const defaultImage =
        source === 'c'
            ? {
                  name: 'Take Photos',
                  src: 'https://cdn1.droom.in/photos/images/eco/dashboard/take-photo.svg',
                  alt: 'Take Photos'
              }
            : {
                  name: 'Upload Photos',
                  src: 'https://cdn1.droom.in/photos/images/eco/dashboard/upload-photo.svg',
                  alt: 'Upload Photos'
              };

    if (!images.length) {
        return (
            <div className="box d-flex flex-column align-items-center justify-content-center text-center position-relative">
                <img src={defaultImage.src} className="mt-2" alt={defaultImage.alt} />
                <label htmlFor="id" className="lh-sm box-title mt-1">
                    {defaultImage.name}
                </label>
            </div>
        );
    }

    return images.map(image => (
        <div
            key={image.name}
            className="box d-flex flex-column align-items-center justify-content-center text-center position-relative"
        >
            <img width="60" height="60" src={URL.createObjectURL(image)} className="mt-2" alt={image.name} />
            <label htmlFor="id" className="lh-sm box-title mt-1">
                {image.name}
            </label>
        </div>
    ));
};

const CommonPhotoUpload = ({ componentOptions, componentDetails }) => {
    const inputRef = useRef(null);
    const dispatch = useDispatch();

    const { orderNumber, technicianId } = useSelector(state => state.order);
    const { files } = useSelector(state => state.form);

    const { parameterId, componentId, bucketId, screenId } = componentDetails;
    const { title, description, is_required = 0, max_images, upload_source } = componentOptions;
    const inputName = `ECO_${technicianId}-${orderNumber}-${bucketId}-${screenId}-${parameterId}-${componentId}`;
    const selectedFiles = files?.[inputName] || [];

    const handleFileChange = e => {
        dispatch(resetFiles({ name: inputName }));
        Array.from(e.target.files).forEach(file => {
            if (parseInt(process.env.COMPRESS_IMAGE || 1)) {
                imageCompression(file, compressionOptions).then(blob => {
                    dispatch(
                        updateFiles({
                            name: inputName,
                            file: new File([blob], file.name, { type: file.type })
                        })
                    );
                });
            } else {
                dispatch(
                    updateFiles({
                        name: inputName,
                        file: file
                    })
                );
            }
        });
    };

    const clickHandler = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    return (
        <div>
            <Fragment>
                <div className="no-of-files mt-2">
                    {selectedFiles.length}/<span>{max_images}</span> Photo(s) Uploaded <sup>*</sup>
                </div>
                <span className="mandatory-img">{title ? title : description}</span>
                <div className="d-flex gap-3 get-photo-el my-3" onClick={clickHandler}>
                    <ImageView source={upload_source} images={selectedFiles} />
                    {upload_source == 'c' ? (
                        <TakePhotos
                            inputRef={inputRef}
                            name={inputName}
                            required={parseInt(is_required)}
                            handleFileChange={handleFileChange}
                            componentOptions={componentOptions}
                        />
                    ) : (
                        <UploadPhotos
                            required={parseInt(is_required)}
                            inputRef={inputRef}
                            name={inputName}
                            handleFileChange={handleFileChange}
                            componentOptions={componentOptions}
                        />
                    )}
                    <Form.Control.Feedback type="invalid">
                        {selectedFiles.length ? '' : 'Please select a file.'}
                    </Form.Control.Feedback>
                </div>
            </Fragment>
        </div>
    );
};

export default CommonPhotoUpload;
