import { Helmet } from "react-helmet";
const MetaTags = ()=>{
    return (
        <Helmet>
            <title>Car Mechanic Shop near me, Used Car Inspection Services Online India</title>
           <meta name="description" content="Car Inspection – Eco inspection is providing door to door certified car inspection services for second hand car in India. Get full car health check up report now!"/>
            {/* <!-- Twitter Card data --/> */}
            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:site" content="@ecoinspection"/>
            <meta name="twitter:title" content="Car Inspection, Online Used Car Inspection Services in India"/>
            <meta name="twitter:description" content="Before buying any second-hand car book our car inspection expert to inspect the car and get a detailed report about car condition. Eco Inspection is providing door to door pre-purchase car inspection services in all over India."/>
            <meta name="twitter:creator" content="ecoinspection.in/car-inspection"/>

            {/* <!-- Twitter summary card with large image must be at least 280x150px --/> */}
            <meta name="twitter:image:src" content="https://cdn1.droom.in/imgusr/droomEco/content/car-inspection-img.png"/>
            <meta name="twitter:app:name:googleplay" content="ECO Inspection"/>
            <meta name="twitter:app:url:googleplay" content="https://play.google.com/store/apps/details?id=in.droom.eco"/>

            {/* <!-- Open Graph data --/> */}
            <meta property="og:title" content="Car Inspection, Online Used Car Inspection Services in India" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="ecoinspection.in/car-inspection" /> 
            <meta property="og:image" content="https://cdn1.droom.in/imgusr/droomEco/content/car-inspection-img.png" />
            <meta property="og:description" content="Before buying any second-hand car book our car inspection expert to inspect the car and get a detailed report about car condition. Eco Inspection is providing door to door pre-purchase car inspection services in all over India." />
            <meta property="og:site_name" content="@ecoinspection" />
            <meta property="og:locale" content="en_US" />

            {/* <!-- Schema.org markup for Google+ --/> */}
            <meta itemprop="name" content="Car Mechanic Shop near me, Used Car Inspection Services Online India"/>
            <meta itemprop="description" content="Car Inspection – Eco inspection is providing door to door certified car inspection services for second hand car in India. Get full car health check up report now!"/>
            <meta itemprop="image" content="https://cdn1.droom.in/imgusr/droomEco/content/car-inspection-img.png"/>

            <link rel="canonical" href="https://ecoinspection.in/car-inspection"/>
        </Helmet>
    );
}
export default MetaTags;