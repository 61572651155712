const HowEcoWorks = () => {
  return (
    <div className="container-fluid my-md-5 my-2">
      <h2 className="text-center fs-2 cp-bold text-capitalize">
        how ECO works?
      </h2>
      <ul
        className="nav nav-tabs m-auto mb-1 d-table border-0 eco-custom-tabs"
        id="howEcoTab"
        role="tablist"
      >
        <li className="nav-item d-table-cell" role="presentation">
          <button
            className="nav-link text-capitalize cp-medium fs-5 lh-1 active"
            id="how-eco-buyer-tab"
            data-bs-toggle="tab"
            data-bs-target="#how-eco-buyer"
            type="button"
            role="tab"
            aria-controls="how-eco-buyer"
            aria-selected="true"
          >
            for buyer
          </button>
        </li>
        <li className="nav-item d-table-cell" role="presentation">
          <button
            className="nav-link text-capitalize cp-medium fs-5 lh-1"
            id="how-eco-tech-tab"
            data-bs-toggle="tab"
            data-bs-target="#how-eco-tech"
            type="button"
            role="tab"
            aria-controls="how-eco-tech"
            aria-selected="false"
          >
            for technician
          </button>
        </li>
      </ul>
      <div
        className="tab-content how-tab-content p-md-4 p-0 pt-0 pe-md-0"
        id="howEcoWorks"
      >
        <div
          className="tab-pane fade show active"
          id="how-eco-buyer"
          role="tabpanel"
          aria-labelledby="how-eco-buyer-tab"
        >
          <div className="row">
            <div className="col-lg-11 offset-lg-1 fs-4 text-gray cp-medium mt-3 mb-4 lh-1">
              Get ECO Inspection Premium Report
            </div>
            <div className="col-lg-7 col-md-6 offset-lg-1">
              <div className="row gy-3">
                <div className="col-lg-6">
                  <div className="hiw-card blue-bg rounded-15 d-flex shadow overflow-hidden">
                    <div className="step-count text-center p-2 text-white lh-1">
                      <div className="text-uppercase mt-4 cp-medium">step</div>
                      <div className="fs-3 cp-bold">O1</div>
                    </div>
                    <div className="step-count-details bg-white p-2 position-relative pl-1">
                      <div className="fs-14 text-gray cp-medium lh-1 mb-2 mt-1">
                        Place Auto Inspection Request
                      </div>
                      <ul className="d-flex flex-column list-styled-disc ps-3 lh-1">
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Provide Seller Details
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Provide Vehicle Details
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Order Inspection
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Track your Order
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hiw-card orange-bg rounded-15 d-flex shadow overflow-hidden">
                    <div className="step-count text-center p-2 text-white lh-1">
                      <div className="text-uppercase mt-4 cp-medium">step</div>
                      <div className="fs-3 cp-bold">O2</div>
                    </div>
                    <div className="step-count-details bg-white p-2 position-relative pl-1">
                      <div className="fs-14 text-gray cp-medium lh-1 mb-2 mt-1">
                        Auto Inspection Request gets forwarded to ECO
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hiw-card purple-bg rounded-15 d-flex shadow overflow-hidden">
                    <div className="step-count text-center p-2 text-white lh-1">
                      <div className="text-uppercase mt-4 cp-medium">step</div>
                      <div className="fs-3 cp-bold">O3</div>
                    </div>
                    <div className="step-count-details bg-white p-2 position-relative pl-1">
                      <div className="fs-14 text-gray cp-medium lh-1 mb-2 mt-1">
                        Order gets mapped to the Technician
                      </div>
                      <ul className="d-flex flex-column list-styled-disc ps-3 lh-1">
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Based on Technician's service Location
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Based on Technician's SLA
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Based on Technician's Availability
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Based on Technician's Existing Orders
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hiw-card pink-bg rounded-15 d-flex shadow overflow-hidden">
                    <div className="step-count text-center p-2 text-white lh-1">
                      <div className="text-uppercase mt-4 cp-medium">step</div>
                      <div className="fs-3 cp-bold">O4</div>
                    </div>
                    <div className="step-count-details bg-white p-2 position-relative pl-1">
                      <div className="fs-14 text-gray cp-medium lh-1 mb-2 mt-1">
                        Technician performs Auto Inspection through ECO
                      </div>
                      <ul className="d-flex flex-column list-styled-disc ps-3 lh-1">
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Technician perform Inspection on 121 checkpoints
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Rate each parameter out of 10
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Upload Photos
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hiw-card green-bg rounded-15 d-flex shadow overflow-hidden">
                    <div className="step-count text-center p-2 text-white lh-1">
                      <div className="text-uppercase mt-4 cp-medium">step</div>
                      <div className="fs-3 cp-bold">O5</div>
                    </div>
                    <div className="step-count-details bg-white p-2 position-relative pl-1">
                      <div className="fs-14 text-gray cp-medium lh-1 mb-2 mt-1">
                        Receive Digital Inspection Report
                      </div>
                      <ul className="d-flex flex-column list-styled-disc ps-3 lh-1">
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Once Technician completes the inspection you will
                          receive the inspection report
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-table m-auto mt-md-5 mb-5 mt-4">
                    <a
                      href={
                        process.env.REACT_APP_DROOM_WEBSITE_URL + '/inspection'
                      }
                      className="btn btn-primary ps-5 pe-5 p-2 text-white text-capitalize cp-medium"
                    >
                      Book Inspection
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pe-lg-0">
              <figure className="figure">
                <img
                  src={
                    process.env.REACT_APP_CDN_DOMAIN +
                    '/imgusr/droomresponsive/img/content/hiw-buyer-img.jpg'
                  }
                  className="figure-img img-fluid"
                  alt="How ECO works"
                />
              </figure>
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="how-eco-tech"
          role="tabpanel"
          aria-labelledby="how-eco-tech-tab"
        >
          <div className="row">
            <div className="col-lg-11 offset-lg-1 fs-4 text-gray cp-medium mt-2 mb-4 lh-1">
              Learn Inspect Earn
            </div>
            <div className="col-lg-7 col-md-6 offset-lg-1">
              <div className="row gy-3">
                <div className="col-lg-6">
                  <div className="hiw-card blue-bg rounded-15 d-flex shadow overflow-hidden">
                    <div className="step-count text-center p-2 text-white lh-1">
                      <div className="text-uppercase mt-4 cp-medium">step</div>
                      <div className="fs-3 cp-bold">O1</div>
                    </div>
                    <div className="step-count-details bg-white p-2 position-relative pl-1">
                      <div className="fs-14 text-gray cp-medium lh-1 mb-2 mt-1">
                        Register With ECO
                      </div>
                      <ul className="d-flex flex-column list-styled-disc ps-3 lh-1">
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Join ECO for Free
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Complete your Phone, Email and Document Verification
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Complete your Profile
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hiw-card orange-bg rounded-15 d-flex shadow overflow-hidden">
                    <div className="step-count text-center p-2 text-white lh-1">
                      <div className="text-uppercase mt-4 cp-medium">step</div>
                      <div className="fs-3 cp-bold">O2</div>
                    </div>
                    <div className="step-count-details bg-white p-2 position-relative pl-1">
                      <div className="fs-14 text-gray cp-medium lh-1 mb-2 mt-1">
                        Get Trained Using ECO Tutorials
                      </div>
                      <ul className="d-flex flex-column list-styled-disc ps-3 lh-1">
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Watch Tutorials anywhere anytime
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Undergo Simple test
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Become Certified Technician
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hiw-card purple-bg rounded-15 d-flex shadow overflow-hidden">
                    <div className="step-count text-center p-2 text-white lh-1">
                      <div className="text-uppercase mt-4 cp-medium">step</div>
                      <div className="fs-3 cp-bold">O3</div>
                    </div>
                    <div className="step-count-details bg-white p-2 position-relative pl-1">
                      <div className="fs-14 text-gray cp-medium lh-1 mb-2 mt-1">
                        Receive Orders From ECO
                      </div>
                      <ul className="d-flex flex-column list-styled-disc ps-3 lh-1">
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Set your own scheduling preferences
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Accept Orders based on your availability
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hiw-card pink-bg rounded-15 d-flex shadow overflow-hidden">
                    <div className="step-count text-center p-2 text-white lh-1">
                      <div className="text-uppercase mt-4 cp-medium">step</div>
                      <div className="fs-3 cp-bold">O4</div>
                    </div>
                    <div className="step-count-details bg-white p-2 position-relative pl-1">
                      <div className="fs-14 text-gray cp-medium lh-1 mb-2 mt-1">
                        Perform Inspection Through ECO
                      </div>
                      <ul className="d-flex flex-column list-styled-disc ps-3 lh-1">
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Perform Inspection on 121 checkpoints
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Rate each parameter out of 10
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Upload Photos
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Complete Inspection
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="hiw-card green-bg rounded-15 d-flex shadow overflow-hidden">
                    <div className="step-count text-center p-2 text-white lh-1">
                      <div className="text-uppercase mt-4 cp-medium">step</div>
                      <div className="fs-3 cp-bold">O5</div>
                    </div>
                    <div className="step-count-details bg-white p-2 position-relative pl-1">
                      <div className="fs-14 text-gray cp-medium lh-1 mb-2 mt-1">
                        Get Paid
                      </div>
                      <ul className="d-flex flex-column list-styled-disc ps-3 lh-1">
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Get paid for each inspection done
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Timely Payment
                        </li>
                        <li className="d-text-light-gray my-1 list-styled-disc fs-12 pn-regular lh-1">
                          Track your Payment Records
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-table m-auto mt-md-5 mb-5 mt-4">
                    <a
                      href="/signup"
                      className="btn btn-primary ps-5 pe-5 p-2 text-white text-capitalize cp-medium"
                    >
                      Register as Technician
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 pe-lg-0">
              <figure className="figure">
                <img
                  src={
                    process.env.REACT_APP_CDN_DOMAIN +
                    '/imgusr/droomresponsive/img/content/hiw-tech-img.jpg'
                  }
                  className="figure-img img-fluid"
                  alt="How ECO works"
                />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowEcoWorks;
