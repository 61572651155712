import { Card, Container } from 'react-bootstrap';
import OrderButtons from '../Order/OrderButtons';
import OrderBody from './OrderBody';

const OrderDetailsCard = ({
    orderNumber,
    vehicleDetails,
    customerName,
    customerAddress,
    timeSlot,
    onReject,
    onAccept
}) => {
    return (
        <div>
            <Container>
                <Card className="text-center bg-white rounded-sm border-0 shadow-sm">
                    <OrderBody
                        orderNumber={orderNumber}
                        vehicleDetails={vehicleDetails}
                        customerName={customerName}
                        customerAddress={customerAddress}
                        timeSlot={timeSlot}
                    />
                    <div className="p-3">
                        <OrderButtons onAccept={onAccept} onReject={onReject} />
                    </div>
                </Card>
            </Container>
        </div>
    );
};

export default OrderDetailsCard;
