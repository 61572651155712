import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import { ErrorBoundary } from '../../components';
import helmet from '../../static/helmet';

export function Root({ route }) {
    return (
        <>
            <Helmet {...helmet} />

            <div>
                <ErrorBoundary>{renderRoutes(route.routes)}</ErrorBoundary>
            </div>
        </>
    );
}

export default withRouter(Root);
