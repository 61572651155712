import '../css/Banner.css';

const Banner = () => {
    return (
        <div className="hero-banner">
            <div className="banner-content">
                <h1 className="highlight-txt">Hello User,</h1>
                <h3>Welcome to ECO Self Inspection</h3>
                <div className="banner-sub-heading">
                    An Independent, Objective, Unbiased,Comprehensive & Scientific Auto Inspection Service
                </div>
            </div>
            <img
                src="https://cdn1.acedms.com/photos/images//eco/dashboard/banner-img.png"
                alt="ECO Inspection"
                className="banner-img"
            />
        </div>
    );
};

export default Banner;
