import React, { useState, useEffect } from 'react';

const ResendOTP = ({ submitHandler }) => {
    const [timer, setTimer] = useState(60);
    const [isTimerRunning, setIsTimerRunning] = useState(true); // Start the timer initially

    useEffect(() => {
        let intervalId;

        if (isTimerRunning && timer > 0) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => {
                    if (prevTimer === 0) {
                        clearInterval(intervalId);
                        setIsTimerRunning(false);
                        return 0;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [isTimerRunning, timer]);

    const handleResendClick = () => {
        setTimer(60);
        setIsTimerRunning(true);
        if (submitHandler && typeof submitHandler === 'function') {
            submitHandler();
        }
    };

    return (
        <>
            {timer === 0 || !isTimerRunning ? (
                <button
                    className="btn-link bg-transparent border-0 shadow-none p-0 fw-normal text-decoration-none"
                    onClick={handleResendClick}
                >
                    Resend OTP
                </button>
            ) : (
                <p>Resend OTP in: {timer} Seconds</p>
            )}
        </>
    );
};

export default ResendOTP;
