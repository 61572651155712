import { Card } from 'react-bootstrap';
const cardImg = 'https://cdn1.droom.in/photos/images/eco/dashboard/inspection.png';

const PerformInspection = ({ submit }) => {
    return (
        <div className="performance my-4" onClick={submit}>
            <Card style={{ width: '100%', borderRadius: '1rem', overflow: 'hidden' }}>
                <Card.Img variant="top" src={cardImg} />
                <Card.Body>
                    <Card.Title className="text-center fs-2">Perform Inspection</Card.Title>
                </Card.Body>
            </Card>
        </div>
    );
};

export default PerformInspection;
