const OfferingTabs = () => {
    return (
        <ul className="nav nav-tabs m-auto mb-4 d-table border-0 eco-custom-tabs" id="ecoOfferingTab" role="tablist">
            <li className="nav-item d-table-cell" role="presentation">
                <button className="nav-link text-capitalize cp-medium fs-5 lh-1 active" id="cars-tab" data-bs-toggle="tab" data-bs-target="#cars" type="button" role="tab" aria-controls="cars" aria-selected="true">cars</button>
            </li>
            <li className="nav-item d-table-cell" role="presentation">
                <button className="nav-link text-capitalize cp-medium fs-5 lh-1" id="bikes-tab" data-bs-toggle="tab" data-bs-target="#bikes" type="button" role="tab" aria-controls="bikes" aria-selected="false">bikes</button>
            </li>
            <li className="nav-item d-table-cell" role="presentation">
                <button className="nav-link text-capitalize cp-medium fs-5 lh-1" id="scooters-tab" data-bs-toggle="tab" data-bs-target="#scooters" type="button" role="tab" aria-controls="scooters" aria-selected="false">Scooters</button>
            </li>
        </ul>
    );
}

export default OfferingTabs;