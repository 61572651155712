import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    currentBucket: null,
    list: [],
    error: ''
};

export const bucketSlice = createSlice({
    name: 'bucketList',
    initialState,
    reducers: {
        updateBucketList: (state, action) => {
            state.list = action.payload;
        },
        getCurrentBucket: (state, action) => {
            const { bucketId } = action.payload;
            state.currentBucket = state.list.find(bucket => bucket.id == bucketId);
        }
    }
});
export const { updateBucketList, getCurrentBucket } = bucketSlice.actions;
