import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    data: {},
    images: {},
    videos: {}
};

export const aiServiceDataSlice = createSlice({
    name: 'AIServiceDataSlice',
    initialState,
    reducers: {
        updateData: (state, action) => {
            state.data = action.payload?.data || {};
        },
        updateImages: (state, action) => {
            state.images = action.payload?.images || {};
        },
        updateVideos: (state, action) => {
            state.videos = action.payload?.videos || {};
        },
    }
});

export const { updateData, updateImages, updateVideos } = aiServiceDataSlice.actions;
