/*
*
@todo call eco-api "app/settings" for stats
*/

const Stats = () => {
    return (
        <div className="container mb-md-5 mb-2">
            <div className="d-flex justify-content-lg-evenly justify-content-between">
                <div className="value-container position-relative">
                    <div className="sprite stats-bg position-absolute z-index-1"></div>
                    <div className="sprite location ms-1 mt-3 position-relative z-index-2"></div>
                    <div className="stats-value position-relative z-index-2 ms-1 d-inline-block">
                        <p className="mb-0 lh-1 fs-3 text-dark cp-bold"><span id='city'>946</span>+</p>
                        <p className="mb-0 lh-1 text-gray-light fs-11 text-capitalize">Cities</p>
                    </div>
                </div>
                <div className="value-container position-relative">
                    <div className="sprite stats-bg position-absolute z-index-1"></div>
                    <div className="sprite auto ms-1 mt-3 position-relative z-index-2"></div>
                    <div className="stats-value position-relative z-index-2 ms-1 d-inline-block">
                        <p className="mb-0 lh-1 fs-3 text-dark cp-bold"><span id='autoInspection'>156438</span>+</p>
                        <p className="mb-0 lh-1 text-gray-light fs-11 text-capitalize">Auto inspection</p>
                    </div>
                </div>
                <div className="value-container position-relative">
                    <div className="sprite stats-bg position-absolute z-index-1"></div>
                    <div className="sprite expert ms-1 mt-3 position-relative z-index-2"></div>
                    <div className="stats-value position-relative z-index-2 ms-1 d-inline-block">
                        <p className="mb-0 lh-1 fs-3 text-dark cp-bold"><span id='autoExperts'>13304</span>+</p>
                        <p className="mb-0 lh-1 text-gray-light fs-11 text-capitalize">auto experts</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Stats;