const Testimonials = () => {
    return (
        <div className="container">
            <h2 className="text-center fs-2 cp-bold text-capitalize">Testimonials</h2>
            <ul className="nav nav-tabs m-auto mb-1 d-table border-0 eco-custom-tabs" id="testimonialsTab" role="tablist">
                <li className="nav-item d-table-cell" role="presentation">
                    <button className="nav-link text-capitalize cp-medium fs-5 lh-1 active" id="buyer-speak-tab" data-bs-toggle="tab" data-bs-target="#buyer-speak" type="button" role="tab" aria-controls="buyer-speak" aria-selected="true">buyer speak</button>
                </li>
                <li className="nav-item d-table-cell" role="presentation">
                    <button className="nav-link text-capitalize cp-medium fs-5 lh-1" id="technicial-speak-tab" data-bs-toggle="tab" data-bs-target="#technicial-speak" type="button" role="tab" aria-controls="technicial-speak" aria-selected="false">technician speak</button>
                </li>
            </ul>
            <div className="tab-content how-tab-content p-md-4 p-0 pt-0 mt-md-5 mt-3 pe-md-0" id="howEcoWorks">
                <div className="tab-pane fade show active" id="buyer-speak" role="tabpanel" aria-labelledby="buyer-speak-tab">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ratio ratio-16x9">
                                <iframe src="https://www.youtube.com/embed/ygQ3jkY6t18" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                        <div className="col-md-6 ps-md-5">
                            <div id="buyerSpeakCarousel" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <p className="mt-3 lh-md pn-regular">I hate negative surprises so I always try to be clear with all my doubts that is the reason I thought of getting the bike inspected thoroughly before I purchase it. I knew it was a second hand bike but for it it is a new one and I had to be 100% sure whether it can accompany me on those rocky roads on not. There is no doubt I got the actual picture and I was aware where I have to put a little money from my pocket to get it fixed.</p>
                                        <div className="fs-6 text-black mt-3 cp-medium">Tarunpal SIngh</div>
                                    </div>
                                    <div className="carousel-item">
                                        <p className="mt-3 lh-md pn-regular">ECO Inspection service of Droom is good for me as they provided me a detailed inspection report which I had shown to all the buyers who were interested to buy my car. They were happy that they do not need to hire a mechanic to get my car checked nor they were about to trust my words. So I had a proof with me always. Thanks</p>
                                        <div className="fs-6 text-black mt-3 cp-medium">Eshant Verma</div>
                                    </div>
                                    <div className="carousel-item">
                                        <p className="mt-3 lh-md pn-regular">Well i had to hire mechanic 2 times. First I thought of relying on local mechanic with which I was not satisfied. Then I contacted droom for their inspection service. This was a world className service. I still regret why I do not contacted droom earlier. I am highly recommending ECO Inspection service, it is the best.</p>
                                        <div className="fs-6 text-black mt-3 cp-medium">Lipika Sahani</div>
                                    </div>
                                    <div className="carousel-item">
                                        <p className="mt-3 lh-md pn-regular">Let me tell you inspection is necessary for any used vehicle you are purchasing be it a car, bike or scooter. Do not compromise with this part. You may or may not be confident with the purchase of a used car but an inspection will definitely help you to know the actual picture of the car which you can’t see with bare eyes.</p>
                                        <div className="fs-6 text-black mt-3 cp-medium">Smrita Dixit</div>
                                    </div>
                                    <div className="carousel-item">
                                        <p className="mt-3 lh-md pn-regular">When I was selling my scooter then I consulted droom eco inspection. I thought it will be done by the buyers and why will I do it. But later I realized it is essential for sellers as well to get fair market vehicle valuation. You will not get the fair price of your vehicle unless and until you have done the inspection. </p>
                                        <div className="fs-6 text-black mt-3 cp-medium">Vastu Sethi</div>
                                    </div>
                                </div>
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#buyerSpeakCarousel" data-bs-slide-to="0" className="active dots" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#buyerSpeakCarousel" data-bs-slide-to="1" aria-label="Slide 2" className="dots"></button>
                                    <button type="button" data-bs-target="#buyerSpeakCarousel" data-bs-slide-to="2" aria-label="Slide 3" className="dots"></button>
                                    <button type="button" data-bs-target="#buyerSpeakCarousel" data-bs-slide-to="3" aria-label="Slide 4" className="dots"></button>
                                    <button type="button" data-bs-target="#buyerSpeakCarousel" data-bs-slide-to="4" aria-label="Slide 5" className="dots"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tab-pane fade" id="technicial-speak" role="tabpanel" aria-labelledby="technicial-speak-tab">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="ratio ratio-16x9">
                                <iframe src="https://www.youtube.com/embed/ygQ3jkY6t18" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                        </div>
                        <div className="col-md-6 ps-md-5">
                            <div id="techSpeakCarousel" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <p className="mt-3 lh-md pn-regular">Got a call from the seller of the vehicle that he wants to get his car inspected properly, so that he can rightly quote the price of his car. It is rare and first time for me that I got a call from seller. But it is good that people are getting aware and they are understanding the importance of car inspection before selling or buying. I think it is because of Droom who has spread the awareness among all.</p>
                                        <div className="fs-6 text-black mt-3 cp-medium">Vinay Desai</div>
                                    </div>
                                    <div className="carousel-item">
                                        <p className="mt-3 lh-md pn-regular">Sometimes it is challenging because people change the odometer reading abruptly to sell their vehicle on higher rate. I have to do my duty to inspect properly and tell the truth to the buyer and also show the right odometer reading. This sometimes break the trust of buyer and seller, but buyer is always happy with my job.</p>
                                        <div className="fs-6 text-black mt-3 cp-medium">Rajendra Sharma</div>
                                    </div>
                                    <div className="carousel-item">
                                        <p className="mt-3 lh-md pn-regular">It is convenient for me as I do not need to search for customers. Droom under its ECO Inspection service helps me with a long list of customers who want to get their vehicles inspected. Sourcing work and earning money has become easy now.</p>
                                        <div className="fs-6 text-black mt-3 cp-medium">Binoy Saha</div>
                                    </div>
                                    <div className="carousel-item">
                                        <p className="mt-3 lh-md pn-regular">The way I perform as a technician under ECO improves the trust factor of the car owners. We need to check 121 checkpoints and also need to rate each parameter out of 10 and also upload the photos. Such minute level of inspection and detailing usually increases the trust of all the car owners. I find it very useful.</p>
                                        <div className="fs-6 text-black mt-3 cp-medium">Raj Kumar</div>
                                    </div>
                                    <div className="carousel-item">
                                        <p className="mt-3 lh-md pn-regular">People rely on us to know the actual condition of their vehicle, so I cannot give a vague idea to them. I need to be more specific and confident with what I inspection report I provide. The advantages I get after associating with Droom ECO Inspection, the same advantages I must provide to the customers as well. It is a chain and before the customers I am the representative of Droom so I have to maintain the quality of service.</p>
                                        <div className="fs-6 text-black mt-3 cp-medium">Kapil Srivastava</div>
                                    </div>
                                </div>
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#techSpeakCarousel" data-bs-slide-to="0" className="active dots" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#techSpeakCarousel" data-bs-slide-to="1" aria-label="Slide 2" className=" dots"></button>
                                    <button type="button" data-bs-target="#techSpeakCarousel" data-bs-slide-to="2" aria-label="Slide 3" className=" dots"></button>
                                    <button type="button" data-bs-target="#techSpeakCarousel" data-bs-slide-to="3" className="dots" aria-current="true" aria-label="Slide 4"></button>
                                    <button type="button" data-bs-target="#techSpeakCarousel" data-bs-slide-to="4" aria-label="Slide 5" className=" dots"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Testimonials;