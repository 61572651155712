import { useLocation } from "react-router-dom";
import TechnicianSignUp from "./TechnicianSignUp";
import VendorSignUp from "./VendorSignUp";
import '../../../resources/css/signup.css';

const SignUp = () => {
    const search = useLocation().search;
    const activeTab = new URLSearchParams(search).get('for');

    return (
        <div className="signup-section bg-light-gray pt-5 mb-3">

            <ul className="nav nav-tabs m-auto mb-3 d-table border-0 eco-custom-tabs" id="featuresTab" role="tablist">
                <li className="nav-item d-table-cell" role="presentation">
                    <button className={ activeTab == 'vendor' ? "nav-link text-capitalize cp-medium fs-5 lh-1" : "nav-link text-capitalize cp-medium fs-5 lh-1 active"} id="register-tech-tab" data-bs-toggle="tab" data-bs-target="#register-tech" type="button" role="tab" aria-controls="register-tech" aria-selected="true">Register as Technician</button>
                </li>
                <li className="nav-item d-table-cell" role="presentation">
                    <button className={ activeTab == 'vendor' ? "nav-link text-capitalize cp-medium fs-5 lh-1 active" : "nav-link text-capitalize cp-medium fs-5 lh-1"} id="register-vender-tab" data-bs-toggle="tab" data-bs-target="#register-vender" type="button" role="tab" aria-controls="register-vender" aria-selected="false">Register as Vendor</button>
                </li>
            </ul>
            <div className="tab-content signup-tab-content p-0 p-md-4" id="signup">
                <div className={ activeTab == 'vendor' ? "tab-pane fade" : "tab-pane fade show active" } id="register-tech" role="tabpanel" aria-labelledby="register-tech-tab">
                    <div className="container">
                        <div className="card shadow rounded-10 p-lg-5 p-4 bg-white border-0">
                            <div className="row gy-4">
                                <TechnicianSignUp />
                                <div className="col-md offset-lg-1">
                                    <h2 className="fs-4 text-uppercase mb-4">inspection flow for technician</h2>
                                    <figure>
                                        <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomresponsive/img/content/inspection-flow-technician.jpg"} alt="Inspection flow for technician" className="img-fluid" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={activeTab == 'vendor' ? "tab-pane fade show active" : "tab-pane fade"} id="register-vender" role="tabpanel" aria-labelledby="register-vender-tab">
                    <div className="container">
                        <div className="card shadow rounded-10 p-lg-5 p-4 bg-white border-0">
                            <div className="row gy-4">
                                <VendorSignUp />
                                <div className="col-md offset-lg-1">
                                    <h2 className="fs-4 text-uppercase mb-4">inspection flow for vendor</h2>
                                    <figure>
                                        <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomresponsive/img/content/vendor-flow-chart.jpg"} alt="Vendor flow" className="img-fluid" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default SignUp;