import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    step: 0,
    error: ''
};
export const stepSlice = createSlice({
    name: 'stepSlice',
    initialState,
    reducers: {
        increaseStep: state => {
            state.step++;
        },
        decreaseStep: state => {
            if (state.step > 1) {
                state.step--;
            }
        },
        resetStep: state => {
            state.step = 0;
        }
    }
});
export const { increaseStep, decreaseStep, resetStep } = stepSlice.actions;
