import Root from '../pages/Root';
import Home from '../page/home/Main';
import QrReport from '../page/Qr-Report/Main';
import Features from '../page/features/Main';
import CarInspection from '../page/Car-Inspection/Main';
import BikeInspection from '../page/Bike-Inspection/Main';
import ScooterInspection from '../page/Scooter-inspection/Main';
import ForgotPassword from '../page/Forgot-Password/Main';
import SignUp from '../page/SignUp/Main';
import NotFound from '../pages/NotFound';
import PrivacyPolicy from '../page/PrivacyPolicy';
import TermsConditions from '../page/TermsConditions';
import ImageSlider from '../components/ImageSlider';
import Dashboard from '../pages/SelfInspection/Dashboard/Dashboard';
import OrderDetails from '../pages/SelfInspection/Order/OrderDetails';
import BucketPage from '../pages/SelfInspection/Buckets/Bucket';
import OrderStart from '../pages/SelfInspection/Order/OrderStart';
import OrderContinue from '../pages/SelfInspection/Order/OrderContinue';
import OrderCompleted from '../pages/SelfInspection/Order/OrderCompleted';
import Buckets from '../pages/SelfInspection/Buckets';
import UnderDevelopment from '@pages/NotFound/UnderDevelopment';

//AI Based Pages
import AIBuckets from '@pages/SelfInspection/AI/Buckets';
import AIBucket from '@pages/SelfInspection/AI/Buckets/Bucket';
const BASE_URL = '/self-inspection/:orderId';

export const routes = [
    {
        path: '/',
        exact: true,
        menu: 'Home',
        component: Home
    },
    {
        path: '/features',
        exact: true,
        menu: 'Features',
        component: Features
    },
    {
        path: '/car-inspection',
        exact: true,
        menu: 'car-inspection',
        component: CarInspection
    },
    {
        path: '/bike-inspection',
        exact: true,
        menu: 'bike-inspection',
        component: BikeInspection
    },
    {
        path: '/scooter-inspection',
        exact: true,
        menu: 'scooter-inspection',
        component: ScooterInspection
    },
    {
        path: '/signup',
        exact: true,
        menu: 'signup',
        component: SignUp
    },
    {
        path: '/signup/technician',
        exact: true,
        menu: 'signup-technician',
        component: SignUp
    },
    {
        path: '/signup/vendor',
        exact: true,
        menu: 'signup-vendor',
        component: SignUp
    },
    {
        path: '/privacy-policy',
        exact: true,
        menu: 'privacy-policy',
        component: PrivacyPolicy
    },
    {
        path: '/terms-conditions',
        exact: true,
        menu: 'terms-conditions',
        component: TermsConditions
    },
    {
        path: '/report_photos/:order_id/:hash',
        exact: true,
        menu: 'report_photos',
        component: ImageSlider
    },
    {
        path: '/qr-report/:id',
        exact: true,
        menu: 'qr_report',
        component: QrReport
    },
    {
        path: '/forgot-password/:hash',
        exact: true,
        menu: 'forgot_password',
        component: ForgotPassword
    },
    {
        path: `${BASE_URL}/dashboard`,
        exact: true,
        isSelfInspection: true,
        layoutProps: {
            isProtected: false
        },
        menu: 'Dashboard',
        component: Dashboard
    },
    {
        path: `${BASE_URL}/tutorials`,
        exact: true,
        isSelfInspection: true,
        layoutProps: {
            isProtected: false
        },
        menu: 'Tutorials',
        component: UnderDevelopment
    },
    {
        path: `${BASE_URL}/details`,
        exact: true,
        isSelfInspection: true,
        menu: 'Order Details',
        layoutProps: {
            isProtected: true
        },
        component: OrderDetails
    },
    {
        path: `${BASE_URL}/start`,
        exact: true,
        isSelfInspection: true,
        menu: 'Order Start',
        layoutProps: {
            isProtected: true
        },
        component: OrderStart
    },
    {
        path: `${BASE_URL}/continue`,
        exact: true,
        isSelfInspection: true,
        menu: 'Order Continue',
        layoutProps: {
            isProtected: true
        },
        component: OrderContinue
    },
    {
        path: `${BASE_URL}/completed`,
        exact: true,
        isSelfInspection: true,
        menu: 'Order Completed',
        layoutProps: {
            isProtected: true
        },
        component: OrderCompleted
    },
    {
        path: `${BASE_URL}/inspection/start`,
        exact: true,
        isSelfInspection: true,
        menu: 'Inspection Start',
        layoutProps: {
            isProtected: true
        },
        component: Buckets
    },
    {
        path: `${BASE_URL}/inspection/buckets/:bucketId`,
        exact: true,
        isSelfInspection: true,
        menu: 'Inspection Bucket',
        layoutProps: {
            hasFooter: false,
            isProtected: true
        },
        component: BucketPage
    },
    {
        path: `${BASE_URL}/inspection/start-ai`,
        exact: true,
        isSelfInspection: true,
        menu: 'Inspection Start',
        layoutProps: {
            isProtected: true
        },
        component: AIBuckets
    },
    {
        path: `${BASE_URL}/inspection/ai/buckets/:bucketId`,
        exact: true,
        isSelfInspection: true,
        menu: 'Inspection Section',
        layoutProps: {
            isProtected: true
        },
        component: AIBucket
    },
    {
        path: '/*',
        component: NotFound
    }
];
export default [
    {
        component: Root,
        routes
    }
];
