import { Form } from 'react-bootstrap';
import '../css/CustomRadioButtons.css';

const CustomRadioButtons = ({
    name,
    options,
    required,
    optionKey,
    validationError,
    radioLabel,
    selectedOption,
    handleOptionChange
}) => {
    return (
        <div className="d-flex flex-column gap-1 bg-white">
            <label className="custom-radio">
                <input
                    type="radio"
                    name={name}
                    required={required}
                    value={options[optionKey]}
                    checked={selectedOption === options[optionKey]}
                    onChange={event => handleOptionChange(event, options[optionKey], optionKey)}
                />
                <span className="checkmark"></span>
                {radioLabel}
                <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>
            </label>
        </div>
    );
};

export default CustomRadioButtons;
