import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Form, FormControl } from 'react-bootstrap';
import { updateComponentFields } from '@redux/FormRedux/reducers/formSlice';

const DateInput = ({ componentOptions, componentDetails }) => {
    const ref = useRef(null);

    const [validationError, setValidationError] = useState('');

    const dispatch = useDispatch();
    const { id, label = 'Date', is_required = 0, input_format = 'DD-MM-YYYY', title } = componentOptions;

    const {
        parameterId,
        parameterName,
        componentId,
        componentName = null,
        type = null,
        optionKey = null
    } = componentDetails;

    const [compValue, setCompValue] = useState('');

    const submitHandler = e => {
        setValidationError(e.target.validationMessage);
        setCompValue(e.target.value);
        dispatch(
            updateComponentFields({
                parameterId,
                parameterName,
                componentId,
                optionId: id,
                name: componentName,
                title,
                type,
                value: e.target.value,
                optionKey
            })
        );
    };

    useEffect(() => {
        setValidationError(ref.current.validationMessage);
    }, [ref]);

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <FormControl
                ref={ref}
                selected={compValue}
                onChange={submitHandler}
                dateformat={input_format}
                type="date"
                placeholdertext={`Enter ${label}`}
                className="form-control"
                required={parseInt(is_required)}
            />
            <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>
        </Form.Group>
    );
};

export default DateInput;
