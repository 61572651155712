import React, { useState, useEffect } from 'react';
import { NavLink, Link } from "react-router-dom";
import VideoAd from '../page/home/VideoAd';
import Loader from 'react-loader-spinner';

const Header = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const handleHideBurgerMenu=(e)=>{
        if(e.target.parentNode.childNodes.length ==1)
        setIsOpen(false);
    };
    return (
        <header className="header home-banner">
            <div className="container-md">
                <nav id="navbarTop" className="navbar navbar-expand-lg navbar-light bg-default">
                    <div className="container-fluid ps-md-2 pe-md-2 p-0">
                        <Link className="navbar-brand order-1 order-md-0 me-md-3 me-0" to="/">
                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/photos/images/droomresponsive/img/content/eco/eco-icon.svg"} alt="ECO" width="113" className="default-img" />
                            <img src={process.env.REACT_APP_CDN_DOMAIN + "/imgusr/droomresponsive/img/content/eco-footer-logo.png"} alt="ECO" className="fixed-img" />
                        </Link>
                        <button 
                            className="navbar-toggler order-0" 
                            type="button" 
                            data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" 
                            aria-label="Toggle navigation"
                            onClick={() => setIsOpen(true)}
                            
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className={isOpen==false?"collapse navbar-collapse ":"collapse navbar-collapse show"} id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 pn-regular fs-6" onClick={handleHideBurgerMenu}>
                                <li className="nav-item">
                                    <NavLink to="/" exact activeClassName="active" className="nav-link ps-4 pe-4">Home</NavLink>

                                </li>
                                <li className="nav-item">
                                    <NavLink to="/features" exact activeClassName="active" className="nav-link ps-4 pe-4">Features</NavLink>

                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link ps-4 pe-4 dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Services
                                    </Link>
                                    <ul className="dropdown-menu pn-regular" aria-labelledby="navbarDropdown">
                                        <li><NavLink to="/car-inspection" exact activeClassName="active" className="dropdown-item fs-6">Car Inspection</NavLink></li>
                                        <li><NavLink to="/bike-inspection" exact activeClassName="active" className="dropdown-item fs-6">Bike Inspection</NavLink></li>
                                        <li><NavLink to="/scooter-inspection" exact activeClassName="active" className="dropdown-item fs-6">Scooter Inspection</NavLink></li>
                                    </ul>
                                </li>
                                <li className="nav-item">

                                    <NavLink to="/signup" exact activeClassName="active" className="nav-link ps-4 pe-4">Sign up</NavLink>

                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link ps-4 pe-4" to="#" data-bs-toggle="modal" data-bs-target="#watchEcoVideo"><span className="sprite youtube d-inline-block"></span> Watch ECO TV</Link>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link ps-4 pe-4"
                                        href="https://play.google.com/store/apps/details?id=in.droom.eco"
                                        rel="noreferrer" target="_blank"><span className="sprite playstore d-inline-block"></span> Download App</a>

                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>

            </div>
            <VideoAd />
            {/* Loader */}
            <div id="eco-loader" style={{
                width: "100%",
                height: "100",
                display: "none",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
            </div>
        </header>

    );
}

export default Header;