import { useState } from 'react';
import '@resources/css/tutorial.css';
import { useHistory } from 'react-router-dom';
import { Form, Button, ListGroup } from 'react-bootstrap';

const BucketGuidelines = ({ guidelines = [], bucket }) => {
  const history = useHistory();
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleSubmit = () => {
      history.push(`ai/buckets/${bucket.id}`);
  };

  return (
    <div>
      <h3 className="text-center mt-4 text-underline">Guidelines</h3>
      <p>Read the Instruction below, understand & proceed further.</p>
      {guidelines && guidelines.length > 0 && (
        <ListGroup as="ol" numbered bsPrefix="list-items">
            {guidelines.map((guideline, index) => (
                <ListGroup.Item as="li" key={index}>{guideline}</ListGroup.Item>
            ))}
        </ListGroup>
      )}

      <Form.Group>
        <Form.Check 
          type="checkbox" 
          label="I understand the instructions." 
          checked={isChecked} 
          onChange={handleCheckboxChange} 
        />
      </Form.Group>

      <Button className="mt-3 w-100"  variant={isChecked ? 'primary' : 'secondary'} disabled={!isChecked} onClick={handleSubmit}>
        Proceed
      </Button>
    </div>
  );
};

export default BucketGuidelines;