import React, { useRef, useState } from 'react';

const VideoUpload = ({handleVideoChange}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setPreviewVideo(URL.createObjectURL(file));
  };

  const handleUpload = () => {
    // You can implement the upload functionality here
    // For demonstration, I'm just logging the selected file
    if (selectedFile) {
      console.log('Selected file:', selectedFile);
      // Here you can implement your upload logic using APIs like FormData, fetch, axios, etc.
    } else {
      console.log('No file selected');
    }
  };

  return (
    <div className='d-flex flex-column align-items-center'>
      <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={handleVideoChange} accept='video/*' />
      <div className='border d-flex justify-content-center align-items-center flex-column rounded-4 fs-6 text-dark p-4 pt-2 pb-2 h-25'
        onClick={() => fileInputRef.current.click()}>
        <div>
          <img src="https://cdn1.acedms.com/photos/images/droomresponsive/img/content/buyers-1/uploadphoto.png" alt="" />
        </div>
        <div className='text-center fs-12'>Upload <br /> Video</div>
      </div>
      {previewVideo && (
        <div className='mt-3'>
          <video controls className='w-100'>
            <source src={previewVideo} type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
    </div>
  );
};

export default VideoUpload;
