import { Link } from "react-router-dom";
import InnerPageBanner from '../InnerPageBanner';
import { useEffect } from "react";
import MetaTags from "./MetaTags";
const CarInspection = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (<>
        <MetaTags />
        <InnerPageBanner heading={'Get A Used Car Inspection With ECO'} bgImage={'car-inspection-bg'} />
        <div className="service-section container-fluid mb-3 mt-3">
            <div className="row">
                <div className="col-sm-12 mb-4 text-center">
                    <a href={process.env.REACT_APP_DROOM_WEBSITE_URL + '/inspection'} className="btn btn-primary px-4 py-2 text-capitalize">Book Inspection</a>
                </div>
                <div className="col-sm-7 col-md-6 offset-md-1">
                    <h2 className="fs-4 text-gray cp-medium">CAR INSPECTION AND WHY<br /> IS IT NECESSARY?</h2>
                    <div className="fs-5 mt-3 mb-2 text-gray"> Car Inspection</div>
                    <p className="pn-regular fs-6">If you own a car and have been using it for some time, then car check up or inspection is one of the main things that should be kept in mind. Paying good attention to your car’s overall health is necessary and this keeps you away from most of the problems related to your car. Also, safety plays the most important role when it comes to owning or driving a car. Keeping a periodic check on all the parts of your car and repairing and maintaining them from time to time helps you keep yourself and your car safe. Car inspection should be carried out by a professional mechanic who will understand in and out of a car and will provide you with a detailed car inspection report.</p>
                </div>
                <div className="col-sm-5 col-md-5 pe-md-0">
                    <div className="d-block">
                        <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomresponsive/img/content/hiw-tech-img.jpg"} alt="Car inspection" className="w-100" />
                    </div>
                </div>
            </div>
            <div className="row mb-md-4">
                <div className="col-sm-5 col-md-3 order-1 order-md-0 offset-md-1">
                    <div className="d-block">
                        <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomresponsive/img/content/car-inspection-img.jpg"} alt="Car inspection" className="img-fluid" />
                    </div>
                </div>
                <div className="col-sm-7 order-0 order-md-1 col-md-7">
                    <div className="fs-5 mt-3 mb-2 text-gray">Where to find a Car Mechanic near me?</div>
                    <p className="pn-regular fs-6">If you own a car and have been using it for some time, then car check up or inspection is one of the main things that should be kept in mind. Paying good attention to your car’s overall health is necessary and this keeps you away from most of the problems related to your car. Also, safety plays the most important role when it comes to owning or driving a car. Keeping a periodic check on all the parts of your car and repairing and maintaining them from time to time helps you keep yourself and your car safe. Car inspection should be carried out by a professional mechanic who will understand in and out of a car and will provide you with a detailed car inspection report.</p>
                </div>
                <div className="col-sm-12 col-md-10 offset-md-1 order-3">
                    <div className="fs-5 mt-3 mb-2 text-gray">What will be Included in a used Car Inspection?</div>
                    <p className="pn-regular fs-6">The used car inspection done by our mechanics includes everything related to a car check up. It includes a detailed analysis of various parts of your car such as body of the vehicle, paint, damage caused by accident, rust, etc. Before buying any car, you should avail this service, which is known as pre purchase car inspection. Also, at the time of buying a used car, this is necessary; as you never know what is hiding behind the shining outer appearance of the second-hand car you are going to buy. ECO helps you get all the necessary information regarding a used car’s health. Used car inspections done by ECO are carried out by experienced technicians at the best price possible. It is possible to contact cheap car mechanics near me and avail the best and reliable car inspection services. Always remember that checking all the parts of a car after a certain period of time helps to keep its value high in the market. Even if you are searching for services of car inspection in Bangalore, you can avail it by one click on your desired location at your required time.</p>
                </div>
            </div>

        </div>
    </>
    );
}
export default CarInspection;