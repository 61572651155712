import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const RequestModal = ({ onAccept }) => {
    const [show, setShow] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header style={{ border: 0 }} closeButton />
                <Modal.Body>
                    <img
                        src="https://cdn1.acedms.com/imgusr/eco/dashboard/ninja.png"
                        alt="ECO Ninja"
                        style={{ width: '100px', margin: '0 auto', display: 'block' }}
                    />
                    <Modal.Title className="text-center mt-3">Are you sure want to accept this request?</Modal.Title>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center border-0">
                    <Button variant="secondary" onClick={handleClose} className="px-5 p-2">
                        No
                    </Button>
                    <Button variant="primary" onClick={onAccept} className="px-5 p-2">
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RequestModal;
