import { Helmet } from "react-helmet";
const MetaTags = ()=>{
    return (
        <Helmet>
            <title>Choose Right with ECO Features</title>
           <meta name="description" content="ECO provides complete features for Visual and Functional Assessment, Wheel Assembly, Checking the Vehicle Interior & Exterior."/>
            {/* <!-- Twitter Card data --/> */}
            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:site" content="@ecoinspection"/>
            <meta name="twitter:title" content="Choose Right with ECO Features"/>
            <meta name="twitter:description" content="ECO provides complete features for Visual and Functional Assessment, Wheel Assembly, Checking the Vehicle Interior & Exterior."/>
            <meta name="twitter:creator" content="ecoinspection.in/features"/>

            {/* Twitter summary card with large image must be at least 280x150px */}
            <meta name="twitter:image:src" content="//cdn1.droom.in/imgusr/droomEco/content/car1.jpg"/>
            <meta name="twitter:app:name:googleplay" content="ECO Inspection"/>
            <meta name="twitter:app:url:googleplay" content="https://play.google.com/store/apps/details?id=in.droom.eco"/>

            {/* <!-- Open Graph data --/> */}
            <meta property="og:title" content="Choose Right with ECO Features" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="ecoinspection.in/features" /> 
            <meta property="og:image" content="//cdn1.droom.in/imgusr/droomEco/content/car1.jpg" />
            <meta property="og:description" content="ECO provides complete features for Visual and Functional Assessment, Wheel Assembly, Checking the Vehicle Interior & Exterior." />
            <meta property="og:site_name" content="@ecoinspection" />
            <meta property="og:locale" content="en_US" />

            {/* <!-- Schema.org markup for Google+ --/> */}
            <meta itemprop="name" content="Choose Right with ECO Features"/>
            <meta itemprop="description" content="ECO provides complete features for Visual and Functional Assessment, Wheel Assembly, Checking the Vehicle Interior & Exterior."/>
            <meta itemprop="image" content="//cdn1.droom.in/imgusr/droomEco/content/car1.jpg"/>

            <link rel="canonical" href="https://ecoinspection.in/features" />
        </Helmet>
    );
}
export default MetaTags;