import { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Popup } from '../../components';
import VendorMetaTags from "./VendorMetaTags";
import { useForm } from "react-hook-form";

const VendorSignUp = () => {

    const { register, handleSubmit,reset, formState: { errors },clearErrors,setValue } = useForm();
    const [showPopup, setShowPopup] = useState('');
   
    const history = useHistory();

    const onSubmit = (data,e) => {

        e.preventDefault();
        fetch(process.env.REACT_APP_ECO_API_URL + '/vendor_registration', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }).then(response => response.json()).then(data => {
            setShowPopup(data);
            
            setInterval(() => window.location.href = '?for=vendor', 5000);
        }).catch(err => {
            console.log(err);
        })
    }


    const handleLocation = (pincode) => {
        if (pincode.length == 6) {
            fetch(process.env.REACT_APP_ECO_API_URL + '/locations?cust_pincode=' + pincode, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }

            }).then(response => response.json()).then(data => {
                if (data.StatusCode == "1") {
                    setValue('city', data.Result.city.city_name, { shouldValidate: true });
                    setValue('state', data.Result.state.state_name, { shouldValidate: true })
                }
            });
        }


    }

    return (
        <div className="col-md">
            <VendorMetaTags/>
            {showPopup ? <Popup showPopup={showPopup} /> : null}
            <form onSubmit={handleSubmit(onSubmit)} id="create-vendor-form">
                <h2 className="fs-4 text-uppercase">Create your account</h2>
                <div className="fs-6 text-gray-light pn-regular mb-4">All Fields are Mandatory</div>
                <div className="form-floating mb-3">
                    <input type="hidden" {...register("source")} value="eco_web"/>
                    <input type="text" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="vendorName" placeholder="Full Name" 
                    {...register("vendor_name",{required:true})} />
                    {errors.vendor_name?.type === 'required' && <p className="alert-danger text-center">Name is required</p>}
                    <label className="ps-0" htmlFor="vendorName">Full Name</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="email" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="vendorEmail" placeholder="vendor.name@gmail.in"  {...register("vendor_email",{required:true})}/>
                    {errors.vendor_email?.type === 'required' && <p className="alert-danger text-center">Email is required</p>}
                    <label className="ps-0" htmlFor="vendorEmail">Email ID</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="number" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="vendorNumber" placeholder="Phone Number" 
                    {...register("vendor_phone",{required:true,minLength:10,maxLength:10})}/>
                    {errors.vendor_phone?.type === 'required' && <p className="alert-danger text-center">Phone is required</p>}
                    {(errors.vendor_phone?.type === 'minLength' || errors.vendor_phone?.type === 'maxLength') && <p className="alert-danger text-center">Phone number length must be 10 digits</p>}
                    <label className="ps-0" htmlFor="vendorNumber">Phone Number</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="number" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="vendorPinCode" placeholder="Pin Code"  
                    {...register("pincode",{required:true,minLength:6,maxLength:6})} onKeyUp={(e) => handleLocation(e.target.value)} />
                    {errors.pincode?.type === 'required' && <p className="alert-danger text-center">Pincode is required</p>}
                    {(errors.pincode?.type === 'minLength' || errors.pincode?.type === 'maxLength') && <p className="alert-danger text-center">Pincode length must be 6 character</p>}
                    <label className="ps-0" htmlFor="vendorPinCode">Pin Code</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="vendorCityName" placeholder="Enter City Name"  
                    {...register("city",{required:true})}/>
                    {errors.city?.type === 'required' && <p className="alert-danger text-center">City is required</p>}
                    <label className="ps-0" htmlFor="vendorCityName">Enter City Name</label>
                    {/* <div className="position-absolute top-50 end-0 translate-bottom">
                        <i role="button" className="sprite search-btn"></i>
                    </div> */}
                </div>
                {/* <div className="my-4">
                    <Link to="#" role="button" className="btn-link d-flex text-decoration-none fs-6"><span className="sprite gps me-2"></span> Detect My Location</Link>
                </div> */}
                <div className="form-floating mb-3">
                    <input type="text" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="vendorState" placeholder="Enter State Name"  
                    {...register("state",{required:true})}/>
                    {errors.state?.type === 'required' && <p className="alert-danger text-center">State is required</p>}
                    <label className="ps-0" htmlFor="vendorState">State</label>
                </div>

                <div className="form-floating mb-3">
                    <input type="text" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="vendorAddress" placeholder="Building/Area/Locality"  
                    {...register("vendor_address",{required:true})}/>
                    {errors.vendor_address?.type === 'required' && <p className="alert-danger text-center">Address is required</p>}
                    <label className="ps-0" htmlFor="vendorAddress">Building/Area/Locality</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="contactName" placeholder="Contact Name"  
                    {...register("contact_name",{required:true})}/>
                    {errors.contact_name?.type === 'required' && <p className="alert-danger text-center">Contact name is required</p>}
                    <label className="ps-0" htmlFor="vendorPinCode">Contact Name</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="text" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="contactEmail" placeholder="Contact Email" 
                    {...register("contact_email",{required:true})} />
                    {errors.contact_email?.type === 'required' && <p className="alert-danger text-center">Contact email is required</p>}
                    <label className="ps-0" htmlFor="contactEmail">Contact Email</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="number" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="contactPhone" placeholder="Contact Phone"  
                    {...register("contact_phone",{required:true,minLength:10,maxLength:10})}/>
                    {errors.contact_phone?.type === 'required' && <p className="alert-danger text-center">Contact phone is required</p>}
                    {(errors.contact_phone?.type === 'minLength' || errors.contact_phone?.type === 'maxLength') && <p className="alert-danger text-center">Phone number length must be 10 digits</p>}
                    <label className="ps-0" htmlFor="contactPhone">Contact Phone</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="password" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="vendorPassword" placeholder="Password" 
                    {...register("vendor_pwd",{required:true,minLength:8})} />
                    {errors.vendor_pwd?.type === 'required' && <p className="alert-danger text-center">Password is required</p>}
                    {(errors.vendor_pwd?.type === 'minLength') && <p className="alert-danger text-center">Password length must be atleast 8 character.</p>}
                    <label className="ps-0" htmlFor="vendorPassword">Password</label>
                </div>
                {/* <div className="form-floating">
                    <input type="password" className="form-control border-start-0 border-end-0 border-top-0 rounded-0 p-0" id="vendorConfirmPassword" placeholder="Confirm Password" value={vendor_confirm_password} onChange={(e) => setConfirmPassword(e.target.value)} />
                    <label className="ps-0" htmlFor="vendorConfirmPassword">Confirm Password</label>
                </div> */}

                <button type="submit" className="btn btn-primary px-5 mt-lg-5 mt-4 py-2">Submit</button>
            </form>
        </div>
    );
}
export default VendorSignUp;