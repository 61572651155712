import { Helmet } from "react-helmet";
const MetaTags = () => {
    return (
        
        <Helmet>
            <title>Bike Mechanic shop near me, Used Motorcycle inspection Online in India</title>
           <meta name="description" content="Motorcycle/Bike Inspection Services - Check online local bike mechanic shop near me. Use our inspection services before buying and selling any used motorcycle or bike." />

            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:site" content="@ecoinspection"/>
            <meta name="twitter:title" content="Bike Inspection, Online Used Bike Inspection Services in India"/>
            <meta name="twitter:description" content="Used Bike Inspection: Book Bike Inspection services to inspect bike. Eco inspection's trained & certified professionals offer door to door pre-purchase top notch Inspection Services in all over India."/>
            <meta name="twitter:creator" content="ecoinspection.in/bike-inspection"/>


            <meta name="twitter:image:src" content="https://cdn1.droom.in/imgusr/droomEco/content/bike-inspection-img.png"/>
            <meta name="twitter:app:name:googleplay" content="ECO Inspection"/>
            <meta name="twitter:app:url:googleplay" content="https://play.google.com/store/apps/details?id=in.droom.eco"/>


            <meta property="og:title" content="Bike Inspection, Online Used Bike Inspection Services in India" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="ecoinspection.in/bike-inspection" /> 
            <meta property="og:image" content="https://cdn1.droom.in/imgusr/droomEco/content/bike-inspection-img.png" />
            <meta property="og:description" content="Used Bike Inspection: Book Bike Inspection services to inspect bike. Eco inspection's trained & certified professionals offer door to door pre-purchase top notch Inspection Services in all over India." />
            <meta property="og:site_name" content="@ecoinspection" />
            <meta property="og:locale" content="en_US" />


            <meta itemprop="name" content="Bike Mechanic shop near me, Used Motorcycle inspection Online in India"/>
            <meta itemprop="description" content="Motorcycle/Bike Inspection Services - Check online local bike mechanic shop near me. Use our inspection services before buying and selling any used motorcycle or bike."/>
            <meta itemprop="image" content="https://cdn1.droom.in/imgusr/droomEco/content/bike-inspection-img.png"/>

            <link rel="canonical" href="https://ecoinspection.in/bike-inspection"/>
            </Helmet>
        
    );
}

export default MetaTags;