const EcoNews = () => {
    return (
        <div className="container my-4 press-section">
            <h2 className="text-center fs-2 mb-md-4 mt-md-5 cp-bold text-capitalize">eco in the news</h2>
            <div className="row">
                <div className="col-md-6">
                    <div className="card border-0">
                        <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomEco/banners/eco-blog-bt.jpg"} alt="News | ECO" className="rounded-15" />
                        <div className="card-body px-0">
                            <div className="card-title fs-6">Online car sales platform Droom launches physical car inspection app <a href="https://www.businesstoday.in/auto/story/online-car-sales-platform-droom-physical-car-inspection-app-57887-2016-03-30" rel="noreferrer" target="_blank" className="btn-link">Click here to more info</a></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="press-articles ps-md-4">
                        <div className="text-gray-light cp-medium fs-6">Other Articles</div>
                        <div className="fs-5 mb-3 cp-medium">Best resource for Automotive Repair Advice</div>
                        <div className="card border-0 my-3">
                            <div className="row">
                                <div className="col-4 col-md-3">
                                    <a href="https://inc42.com/buzz/eco-launch-droom-carnation/" target="_blank" rel="noreferrer">
                                        <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomEco/banners/eco-blog-4.jpg"} alt="ECO in the news" className="img-fluid rounded-3" />
                                    </a>
                                </div>
                                <div className="col">
                                    <div className="card-text pe-md-5">
                                        <a href="https://inc42.com/buzz/eco-launch-droom-carnation/" target="_blank" rel="noreferrer" className="text-decoration-none text-gray-light pn-regular">Droom Partners With Carnation To Launch 'Eco' An Auto Inspection App For Second-Hand Vehicles.</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 my-3">
                            <div className="row">
                                <div className="col-4 col-md-3">
                                    <a href="https://techvorm.com/droom-launches-eco-app-auto-inspection-solution-useful-buyers-sellers-second-hand-vehicles/" target="_blank" rel="noreferrer">
                                        <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomEco/banners/eco-blog-tech.jpg"} alt="ECO in the news" className="img-fluid rounded-3" />
                                    </a>
                                </div>
                                <div className="col">
                                    <div className="card-text pe-md-5">
                                        <a href="https://techvorm.com/droom-launches-eco-app-auto-inspection-solution-useful-buyers-sellers-second-hand-vehicles/" target="_blank" rel="noreferrer" className="text-decoration-none text-gray-light pn-regular">Droom Launches ECO, A Mobile-Based Auto Inspection Solution In Partnership With Carnation Auto.</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card border-0 my-3">
                            <div className="row">
                                <div className="col-4 col-md-3">
                                    <a href="https://www.entrepreneur.com/article/273212" target="_blank" rel="noreferrer">
                                        <img src={process.env.REACT_APP_CDN_DOMAIN+"/imgusr/droomEco/banners/eco-blog-bt.jpg"} alt="ECO in the news" className="img-fluid rounded-3" />
                                    </a>
                                </div>
                                <div className="col">
                                    <div className="card-text pe-md-5">
                                        <a href="https://www.entrepreneur.com/article/273212" target="_blank" rel="noreferrer" className="text-decoration-none text-gray-light pn-regular">Droom, Carnation Auto together launch auto inspection solution 'ECO'.</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default EcoNews;