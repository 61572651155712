import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SubHeader from '../Common/SubHeader';
import SubmitButton from '../Common/SubmitButton';
import OrderBody from './OrderBody';
import { INSPECTION_STARTED } from './Statuses';

const OrderContinue = () => {
    const history = useHistory();
    const order = useSelector(state => state.order);

    useEffect(() => {
        if (order.orderStatus !== INSPECTION_STARTED) {
            history.push(`start`);
        }
    }, [order.orderStatus]);

    const flowHandler = order => {
        return order.aiBased ? 'inspection/start-ai' : 'inspection/start';
    };

    return (
        <>
            <SubHeader showPageCount={false} />
            <Container className="py-4 pt-5">
                <div className="order-continue bg-white pb-3">
                    <OrderBody
                        orderNumber={order?.orderNumber}
                        vehicleDetails={order?.vehicleDetails}
                        timeSlot={order?.timeSlot}
                        customerName={order?.customerName}
                        customerAddress={order?.customerAddress}
                    />
                    <div className="mx-3">
                        <SubmitButton className="primary-button w-100" onClick={() => history.push(flowHandler(order))}>
                            Continue Inspection
                        </SubmitButton>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default OrderContinue;
