import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

const OfferingContent = () => {
  const initialPrice = {
    inspection_basic_car: '...',
    inspection_basic_bike: '...',
    inspection_basic_scooter: '...',
    inspection_premium_car: '...',
    inspection_premium_bike: '...',
    inspection_premium_scooters: '...',
    inspection_premium_ev_car: '...',
    inspection_premium_plus_car: '...',
    title_certificate_bike: '...',
    title_certificate_scooters: '...',
    engine_diagnostics_car: '...'
  };
  const [price, setPrice] = useState(initialPrice);

  useEffect(() => {
    fetch(
      process.env.REACT_APP_ECO_API_URL +
        '/get_product_data?request_source=true',
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }
    )
      .then(response => response.json())
      .then(data => {
        console.log(data);
        let newprice = {};
        for (var key in data.Result) {
          if (
            typeof data.Result[key].codename != 'undefined' &&
            typeof data.Result[key].price != 'undefined'
          ) {
            newprice = {
              ...newprice,
              [data.Result[key].codename]: data.Result[key].price
            };
          }
        }
        setPrice(newprice);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  return (
    <div
      className="tab-content offerings-tab-content p-0 pb-2 p-md-4"
      id="offering"
    >
      <div
        className="tab-pane fade show active"
        id="cars"
        role="tabpanel"
        aria-labelledby="cars-tab"
      >
        <div className="container-md">
          <div className="fs-3 text-center text-white cp-medium mt-2 mb-md-4 mb-2 pt-2">
            Inspection Services
          </div>
          <div className="row gy-2">
            <div className="col-lg-4 col-md-6">
              <div className="card h-100 mb-2 bg-white text-dark rounded-3">
                <div className="card-body">
                  <div className="card-title text-center mt-2 mb-2 fs-4 text-dark cp-bold">
                    Basic Inspection
                  </div>
                  <div className="price t-purple cp-bold fs-1 lh-1 text-center">
                    ₹{price.inspection_basic_car}
                  </div>
                  <div className="text-center m-3 action-btns">
                    <a
                      href={
                        process.env.REACT_APP_CDN_DOMAIN +
                        '/imgusr/droomresponsive/img/content/eco/basic-inspection-car-sample-report.pdf'
                      }
                      className="btn-link d-table m-auto mb-3 text-decoration-none"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Sample Report
                    </a>
                    <a
                      href={
                        process.env.REACT_APP_DROOM_WEBSITE_URL +
                        '/eco/order?product-code=inspection_basic_car&cat=car'
                      }
                      target="_blank"
                      className="btn btn-primary d-table m-auto text-white cp-medium"
                      rel="noreferrer"
                    >
                      Buy Now
                    </a>
                  </div>
                  <div className="feature-title text-dark fs-6 mb-2">
                    Features
                  </div>
                  <ul className="features list-unstyled pn-regular">
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Complete verification of vehicle condition & information
                      across 40+ checkpoints
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Eco Score for each section of the car
                      [Exterior,Interior,Wheel,Hood]
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Key vehicle highlights like accident history, flood
                      damage, tire Life, bank hypothecation, etc.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card h-100 mb-2 bg-white text-dark rounded-3 overflow-hidden">
                <div className="card-body">
                  <div className="card-title text-center mt-2 mb-2 fs-4 text-dark cp-bold">
                    Premium Inspection
                  </div>
                  <div className="popular-tag fs-11 position-absolute text-warning">
                    Most Popular
                  </div>
                  <div className="price t-yellow cp-bold fs-1 lh-1 text-center">
                    ₹{price.inspection_premium_car}
                  </div>
                  <div className="text-center m-3 action-btns">
                    <a
                      href={
                        process.env.REACT_APP_CDN_DOMAIN +
                        '/imgusr/droomresponsive/img/content/eco/premium-inspection-car-sample-report.pdf'
                      }
                      className="btn-link d-table m-auto mb-3 text-decoration-none"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Sample Report
                    </a>
                    <a
                      href={
                        process.env.REACT_APP_DROOM_WEBSITE_URL +
                        '/eco/order?product-code=inspection_premium_car&cat=car'
                      }
                      target="_blank"
                      className="btn btn-primary d-table m-auto text-white cp-medium"
                      rel="noreferrer"
                    >
                      Buy Now
                    </a>
                  </div>
                  <div className="feature-title text-dark fs-6 mb-2">
                    Features
                  </div>
                  <ul className="features list-unstyled pn-regular">
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Complete verification of vehicle condition & information
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Thorough inspection of 140+ checkpoints across each
                      section of car (Exterior, Interior, Wheels, Hood, Test
                      Drive)
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Repair estimates for damaged vehicle parts
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
              <div className="card h-100 mb-2 bg-white text-dark rounded-3">
                <div className="card-body">
                  <div className="card-title text-center mt-2 mb-2 fs-4 text-dark cp-bold">
                    Premium+ Inspection
                  </div>
                  <div className="price t-orange cp-bold fs-1 lh-1 text-center">
                    ₹{price.inspection_premium_plus_car}
                  </div>
                  <div className="text-center m-3 action-btns">
                    <a
                      href={
                        process.env.REACT_APP_CDN_DOMAIN +
                        '/imgusr/droomresponsive/img/content/eco/premium-inspection-car-sample-report.pdf'
                      }
                      className="btn-link d-table m-auto mb-3 text-decoration-none"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Sample Report
                    </a>
                    <a
                      href={
                        process.env.REACT_APP_DROOM_WEBSITE_URL +
                        '/eco/order?product-code=inspection_premium_plus_car&cat=car'
                      }
                      target="_blank"
                      className="btn btn-primary d-table m-auto text-white cp-medium"
                      rel="noreferrer"
                    >
                      Buy Now
                    </a>
                  </div>
                  <div className="feature-title text-dark fs-6 mb-2">
                    Features
                  </div>
                  <ul className="features list-unstyled pn-regular">
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Complete verification of vehicle condition & information
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Thorough inspection of 150+ checkpoints across each
                      section of car (Exterior, Interior,Wheels, Hood, Test
                      Drive)
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Repair estimates for damaged vehicle parts.
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Vehicle hygiene & cleanliness evaluation and score
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Exclusive video covering entire vehicle
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <Link
                to="#"
                role="button"
                data-bs-target="#compareModal"
                data-bs-toggle="modal"
                className="btn-link text-white d-inline-block mt-4 fs-6"
              >
                Click Here To Compare Between Inspection Services
              </Link>
            </div>
          </div>
          <div className="fs-3 text-capitalize text-center text-white cp-medium mt-4 mb-4">
            other Services
          </div>
          <div className="row gy-2">
            {/* <div className="col-lg-4 col-md-6">
                            <div className="card h-100 mb-2 bg-white text-dark rounded-3">
                                <div className="card-body">
                                    <div className="card-title text-center mt-2 mb-2 fs-4 text-dark cp-bold">Odometer Assurance</div>
                                    <div className="price text-gray-light cp-bold fs-1 lh-1 text-center">
                                        ₹399
                                    </div>
                                    <div className="text-center m-3 action-btns">
                                        <a href={process.env.REACT_APP_CDN_DOMAIN + "/imgusr/droomresponsive/img/content/eco/odometer-assurance-sample-report.pdf"} className="btn-link d-table m-auto mb-3 text-decoration-none" target="_blank">View Sample Report</a>
                                        <a href={process.env.REACT_APP_DROOM_WEBSITE_URL + '/eco-order?product-code=odometer_assurance_car&cat=car'} target="_blank" className="btn btn-primary d-table m-auto text-white cp-medium">Buy Now</a>
                                    </div>
                                    <div className="feature-title text-dark fs-6 mb-2">Features</div>
                                    <ul className="features list-unstyled pn-regular">
                                        <li className="mt-2 mb-2 position-relative ps-3">Thorough check for odometer tampering/fraud by ECO certified technician</li>
                                        <li className="mt-2 mb-2 position-relative ps-3">Cross verification with service records</li>
                                        <li className="mt-2 mb-2 position-relative ps-3">Exclusive odometer assurance certificate / assurance report</li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
            <div className="col-md-4 offset-4">
              <div className="card h-100 mb-2 bg-white text-dark rounded-3 overflow-hidden">
                <div className="card-body">
                  <div className="card-title text-center mt-2 mb-2 fs-4 text-dark cp-bold">
                    Engine Diagnostics
                  </div>
                  <div className="price text-gray-light cp-bold fs-1 lh-1 text-center">
                    ₹{price.engine_diagnostics_car}
                  </div>
                  <div className="text-center m-3 action-btns">
                    <a
                      href={
                        process.env.REACT_APP_CDN_DOMAIN +
                        '/imgusr/droomresponsive/img/content/eco/engine-diagnostics-sample-report.pdf'
                      }
                      className="btn-link d-table m-auto mb-3 text-decoration-none"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Sample Report
                    </a>
                    <a
                      href={
                        process.env.REACT_APP_DROOM_WEBSITE_URL +
                        '/eco/order?product-code=engine_diagnostics_car&cat=car'
                      }
                      target="_blank"
                      className="btn btn-primary d-table m-auto text-white cp-medium"
                      rel="noreferrer"
                    >
                      Buy Now
                    </a>
                  </div>
                  <div className="feature-title text-dark fs-6 mb-2">
                    Features
                  </div>
                  <ul className="features list-unstyled pn-regular">
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Comprehensive evaluation of the engine using a scan tool.
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Check 20 vehicle systems for vehicle fault codes
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Get exclusive details like fuel economy, torque, power,
                      battery, etc.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                            <div className="card h-100 mb-2 bg-white text-dark rounded-3">
                                <div className="card-body">
                                    <div className="card-title text-center mt-2 mb-2 fs-4 text-dark cp-bold">Title Certificate</div>
                                    <div className="price text-gray-light cp-bold fs-1 lh-1 text-center">
                                        ₹99
                                    </div>
                                    <div className="text-center m-3 action-btns">
                                        <a href={process.env.REACT_APP_CDN_DOMAIN + "/imgusr/droomresponsive/img/content/eco/Title_Certificate_Car_Sample_Report.pdf"} className="btn-link d-table m-auto mb-3 text-decoration-none" target="_blank">View Sample Report</a>
                                        <a href={process.env.REACT_APP_DROOM_WEBSITE_URL + '/eco-order?product-code=title_certificate_car&cat=car'} target="_blank" className="btn btn-primary d-table m-auto text-white cp-medium">Buy Now</a>
                                    </div>
                                    <div className="feature-title text-dark fs-6 mb-2">Features</div>
                                    <ul className="features list-unstyled pn-regular">
                                        <li className="mt-2 mb-2 position-relative ps-3">Validation of vehicle ownership</li>
                                        <li className="mt-2 mb-2 position-relative ps-3">Complete verification of vehicle information & documents</li>
                                        <li className="mt-2 mb-2 position-relative ps-3">Check for hypothecation and blacklisting</li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="bikes"
        role="tabpanel"
        aria-labelledby="bikes-tab"
      >
        <div className="container-md">
          <div className="fs-3 text-center text-white cp-medium mt-2 mb-md-4 mb-2 pt-2">
            Inspection Services
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="card h-100 mb-2 bg-white text-dark rounded-3">
                <div className="card-body">
                  <div className="card-title text-center mt-2 mb-2 fs-4 text-dark cp-bold">
                    Basic Inspection
                  </div>
                  <div className="price t-purple cp-bold fs-1 lh-1 text-center">
                    ₹{price.inspection_basic_bike}
                  </div>
                  <div className="text-center m-3 action-btns">
                    <a
                      href={
                        process.env.REACT_APP_CDN_DOMAIN +
                        '/imgusr/droomresponsive/img/content/eco/basic-bike-inspection-sample-report.pdf'
                      }
                      className="btn-link d-table m-auto mb-3 text-decoration-none"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Sample Report
                    </a>
                    <a
                      href={
                        process.env.REACT_APP_DROOM_WEBSITE_URL +
                        '/eco/order?product-code=inspection_basic_bike&cat=bike'
                      }
                      target="_blank"
                      className="btn btn-primary d-table m-auto text-white cp-medium"
                      rel="noreferrer"
                    >
                      Buy Now
                    </a>
                  </div>
                  <div className="feature-title text-dark fs-6 mb-2">
                    Features
                  </div>
                  <ul className="features list-unstyled pn-regular">
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Complete verification of vehicle condition & information
                      across 40+ checkpoints
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Eco Score for each section of the car (Body, Handlebar &
                      Control, Lighting & Switches, Wheels, Oils)
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Key vehicle highlights like accident history, flood
                      damage, tire Life, bank Hypothecation, etc.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card h-100 mb-2 bg-white text-dark rounded-3 overflow-hidden">
                <div className="card-body">
                  <div className="card-title text-center mt-2 mb-2 fs-4 text-dark cp-bold">
                    Premium Inspection
                  </div>
                  <div className="popular-tag fs-11 position-absolute text-warning">
                    Most Popular
                  </div>
                  <div className="price t-yellow cp-bold fs-1 lh-1 text-center">
                    ₹{price.inspection_premium_bike}
                  </div>
                  <div className="text-center m-3 action-btns">
                    <a
                      href={
                        process.env.REACT_APP_CDN_DOMAIN +
                        '/imgusr/droomresponsive/img/content/eco/premium-bike-inspection-sample-report.pdf'
                      }
                      className="btn-link d-table m-auto mb-3 text-decoration-none"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Sample Report
                    </a>
                    <a
                      href={
                        process.env.REACT_APP_DROOM_WEBSITE_URL +
                        '/eco/order?product-code=inspection_premium_bike&cat=bike'
                      }
                      target="_blank"
                      className="btn btn-primary d-table m-auto text-white cp-medium"
                      rel="noreferrer"
                    >
                      Buy Now
                    </a>
                  </div>
                  <div className="feature-title text-dark fs-6 mb-2">
                    Features
                  </div>
                  <ul className="features list-unstyled pn-regular">
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Complete verification of vehicle condition & information
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Thorough inspection of 120+ checkpoints across each
                      section of car (Body, Handlebar & Control, Lighting &
                      Switches, Wheels, Oils, Performance)
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Repair estimates for damaged vehicle parts
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="scooters"
        role="tabpanel"
        aria-labelledby="scooters-tab"
      >
        <div className="container-md">
          <div className="fs-3 text-center text-white cp-medium mt-2 mb-md-4 mb-2 pt-2">
            Inspection Services
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="card h-100 mb-2 bg-white text-dark rounded-3">
                <div className="card-body">
                  <div className="card-title text-center mt-2 mb-2 fs-4 text-dark cp-bold">
                    Basic Inspection
                  </div>
                  <div className="price t-purple cp-bold fs-1 lh-1 text-center">
                    ₹{price.inspection_basic_scooter}
                  </div>
                  <div className="text-center m-3 action-btns">
                    <a
                      href={
                        process.env.REACT_APP_CDN_DOMAIN +
                        '/imgusr/droomresponsive/img/content/eco/basic-scooters-inspection-sample-report.pdf'
                      }
                      className="btn-link d-table m-auto mb-3 text-decoration-none"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Sample Report
                    </a>
                    <a
                      href={
                        process.env.REACT_APP_DROOM_WEBSITE_URL +
                        '/eco/order?product-code=inspection_basic_scooter&cat=scooter'
                      }
                      target="_blank"
                      className="btn btn-primary d-table m-auto text-white cp-medium"
                      rel="noreferrer"
                    >
                      Buy Now
                    </a>
                  </div>
                  <div className="feature-title text-dark fs-6 mb-2">
                    Features
                  </div>
                  <ul className="features list-unstyled pn-regular">
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Complete verification of vehicle condition & information
                      across 40+ checkpoints
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Eco Score for each section of the car (Body, Handlebar &
                      Control, Lighting & Switches, Wheels, Oils)
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Key vehicle highlights like accident history, flood
                      damage, tire Life, bank Hypothecation, etc.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="card h-100 mb-2 bg-white text-dark rounded-3 overflow-hidden">
                <div className="card-body">
                  <div className="card-title text-center mt-2 mb-2 fs-4 text-dark cp-bold">
                    Premium Inspection
                  </div>
                  <div className="popular-tag fs-11 position-absolute text-warning">
                    Most Popular
                  </div>
                  <div className="price t-yellow cp-bold fs-1 lh-1 text-center">
                    ₹{price.inspection_premium_scooters}
                  </div>
                  <div className="text-center m-3 action-btns">
                    <a
                      href={
                        process.env.REACT_APP_CDN_DOMAIN +
                        '/imgusr/droomresponsive/img/content/eco/premium-scooters-inspection-sample-report.pdf'
                      }
                      className="btn-link d-table m-auto mb-3 text-decoration-none"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Sample Report
                    </a>
                    <a
                      href={
                        process.env.REACT_APP_DROOM_WEBSITE_URL +
                        '/eco/order?product-code=inspection_premium_scooters&cat=scooter'
                      }
                      target="_blank"
                      className="btn btn-primary d-table m-auto text-white cp-medium"
                      rel="noreferrer"
                    >
                      Buy Now
                    </a>
                  </div>
                  <div className="feature-title text-dark fs-6 mb-2">
                    Features
                  </div>
                  <ul className="features list-unstyled pn-regular">
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Complete verification of vehicle condition & information
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Thorough inspection of 120+ checkpoints across each
                      section of car (Body, Handlebar & Control, Lighting &
                      Switches, Wheels, Oils, Performance)
                    </li>
                    <li className="mt-2 mb-2 position-relative ps-3">
                      Repair estimates for damaged vehicle parts
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferingContent;
