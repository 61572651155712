const Faqs = () => {
    return (
        <div className="container mb-md-5 mb-2">
            <h2 className="text-center fs-2 mb-md-4 mt-md-5 cp-bold text-capitalize">FAQs</h2>
            <div className="accordion" id="accordionExample">
                <div className="accordion-item border-0">
                    <h2 className="accordion-header text-primary" id="headingOne">
                        <button className="accordion-button ps-3" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">When Vehicle Inspection is required?</button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body ps-3 pt-0 pn-regular">Before selling the used vehicle and also before buying the used vehicle, Inspection is essential.</div>
                    </div>
                </div>
                <div className="accordion-item border-0 px-0">
                    <h2 className="accordion-header text-primary" id="headingTwo">
                        <button className="accordion-button ps-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Who performs the Inspection process?</button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body ps-3 pt-0 pn-regular">A person who has great experience in the field of automobile, has a good knowledge and can fix an automobile, gets on boarded as an auto inspection technician with ECO.</div>
                    </div>
                </div>
                <div className="accordion-item border-0 px-0">
                    <h2 className="accordion-header text-primary" id="headingThree">
                        <button className="accordion-button ps-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">How ECO Inspection is trustworthy?</button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body ps-3 pt-0 pn-regular">ECO Inspection is independent, objective, and unbiased.</div>
                    </div>
                </div>
                <div className="accordion-item border-0 px-0">
                    <h2 className="accordion-header text-primary" id="headingFour">
                        <button className="accordion-button ps-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Why Inspection is important for technician?</button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body ps-3 pt-0 pn-regular">A technician can join ECO, perform inspection and earn, choose flexible hours at own convenience, be their own boss and can also get trained using ECO tutorial.</div>
                    </div>
                </div>
                <div className="accordion-item border-0 px-0">
                    <h2 className="accordion-header text-primary" id="headingFive">
                        <button className="accordion-button ps-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Why Vehicle Inspection is important for buyer?</button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div className="accordion-body ps-3 pt-0 pn-regular">A buyer can get their vehicles inspected by handpicked best certified technicians, get complete transparency and visibility with exhaustive checkpoints and get faulty parts repair estimate.</div>
                    </div>
                </div>
                <div className="accordion-item border-0 px-0">
                    <h2 className="accordion-header text-primary" id="headingSix">
                        <button className="accordion-button ps-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">What are the top Parameters covered in the Vehicle Inspection?</button>
                    </h2>
                    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                        <div className="accordion-body ps-3 pt-0 pn-regular">The top parameters of four-wheeler inspection are visual and functional assessment, under the hood, vehicle exterior, wheel assembly, vehicle interior engine, transmission, axles and exhaust. And top parameters for two-wheeler inspection are tires & wheels, L-lights & electrics, vehicle c-controls, vehicle stands.</div>
                    </div>
                </div>
                <div className="accordion-item border-0 px-0">
                    <h2 className="accordion-header text-primary" id="headingSeven">
                        <button className="accordion-button ps-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">What is the cost of Basic Vehicle Inspection Report?</button>
                    </h2>
                    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                        <div className="accordion-body ps-3 pt-0 pn-regular">The cost of Basic Vehicle Inspection Report for bike is Rs. 149 and car is Rs. 299.</div>
                    </div>
                </div>
                <div className="accordion-item border-0 px-0">
                    <h2 className="accordion-header text-primary" id="headingEight">
                        <button className="accordion-button ps-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">What is the cost of Premium Inspection Report?</button>
                    </h2>
                    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                        <div className="accordion-body ps-3 pt-0 pn-regular">The cost of Premium Vehicle Inspection Report for bike is Rs. 249 and car is Rs. 549.</div>
                    </div>
                </div>
                <div className="accordion-item border-0 px-0">
                    <h2 className="accordion-header text-primary" id="headingNine">
                        <button className="accordion-button ps-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">What are the benefits of Premium Vehicle Inspection Report?</button>
                    </h2>
                    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
                        <div className="accordion-body ps-3 pt-0 pn-regular">The Premium Vehicle Inspection Report includes complete vehicle info with 121 checklist, faulty parts estimated cost, key observation, fair market value with OBV, clear breakup of where attention is required, and vehicle images.</div>
                    </div>
                </div>
                <div className="accordion-item border-0 px-0">
                    <h2 className="accordion-header text-primary" id="headingTen">
                        <button className="accordion-button ps-3 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">How can I place the order?</button>
                    </h2>
                    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
                        <div className="accordion-body ps-3 pt-0 pn-regular">Visit Droom ECO Inspection page – Click Book Now – Choose Location – Choose Vehicle – Choose date – Choose time – Choose the preferred inspection package.</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Faqs;