const VideoAd = () => {
    return (
        <div className="modal compare-modal fade" id="watchEcoVideo" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="watchEcoVideoLabel" aria-hidden="true">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="ratio ratio-16x9">
                            <iframe src="https://www.youtube.com/embed/cSsG1bOmvZc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default VideoAd;