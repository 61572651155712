const CompareModal = () => {
    return (
        <div className="modal compare-modal fade" id="compareModal" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="compareModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header border-0 pb-0">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body pt-0 px-md-5 px-1">
                        <div className="d-block d-sm-flex mt-3">
                            <div className="modal-title cp-bold fs-2 flex-grow-1" id="compareModalLabel">ECO Inspection Package</div>
                            <img src={process.env.REACT_APP_CDN_DOMAIN+"/photos/images/droomresponsive/img/content/eco/eco-icon.svg"} alt="Droom.in" className="align-middle" />
                        </div>
                        <div className="rounded-10 mt-5 mb-4 border border-2 border-info shadow">
                            <div className="table-responsive">
                                <table className="table mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-gray-light text-uppercase fs-4 fw-normal lh-sm">Complete vehicle <br />information</th>
                                            <th className="text-center align-top">
                                                <div className="sprite basic"></div>
                                                <div className="my-1 fs-5">Basic</div>
                                            </th>
                                            <th className="text-center align-top">
                                                <div className="sprite premium"></div>
                                                <div className="my-1 fs-5">Premium</div>
                                            </th>
                                            <th className="text-center align-top">
                                                <div className="sprite premium-plus"></div>
                                                <div className="my-1 fs-5">Premium +</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Overall Score Based On Evaluation of Different (Interior, Wheel, Test, Drive, Exterior & Hood)</td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Vehicle Images (Includes: Damaged/Broken Parts)</td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Overall Section Wise Rating</td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Individual Checkpoint Wise Rating</td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-no">x</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Estimated Cost for Faulty Parts/Repairs</td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Key Obervations (Accidental History, Vehicle age, Flood Damage, Insurance, Loan etc)</td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>OBV (Fair Market Price) Based on Inspection</td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-no">x</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Inspection for Hygiene & Cleanliness</td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-no">x</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-no">x</div>
                                            </td>
                                            <td className="text-center align-top">
                                                <div className="text-center d-inline-block icon rounded-circle p-1 text-white fs-6 lh-1 fw-bold icon-yes">✓</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CompareModal;