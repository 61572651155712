import React from 'react';

const TakePhotos = ({ inputRef, name, componentOptions, handleFileChange, required }) => {
    return (
        <input
            ref={inputRef}
            onChange={handleFileChange}
            type="file"
            accept="image/*"
            id={name}
            name={name}
            required={required}
            className="capture-input"
            capture={componentOptions.front_camera ? 'user' : 'environment'}
        />
    );
};

export default TakePhotos;
