import { Fragment } from 'react';
import Parameter from '../Parameters/Parameter';

const Screen = ({ bucketId, screenId, parameters }) => {
    return (
        <>
            {parameters?.map((parameter, index) => {
                return (
                    <Fragment key={index}>
                        <Parameter
                            bucketId={bucketId}
                            screenId={screenId}
                            key={parameter.id}
                            paramId={parameter.id}
                            paramName={parameter.name}
                            components={parameter?.components}
                        />
                    </Fragment>
                );
            })}
        </>
    );
};

export default Screen;
