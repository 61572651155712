import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const initialState = {
    loading: false,
    form: [
        {
            bucket_id: null,
            bucket_name: null,
            screens: [
                {
                    screen_id: null,
                    screen_name: null,
                    parameters: []
                }
            ]
        }
    ],
    dependentField: {
        make: {
            values: [],
            selected: ''
        },
        model: {
            values: [],
            selected: ''
        },
        year: {
            values: [],
            selected: ''
        },
        trim: {
            values: [],
            selected: ''
        }
    },
    error: '',
    files: {}
};
export const sendScreenData = createAsyncThunk('opt/send', mobile => {
    return axios.post('/drlogin/history-otp', { email: mobile }).then(res => {
        return res.data;
    });
});

const updateParameterFields = (state, action) => {
    const { parameterId, parameterName } = action.payload;
    const index = state.form[0].screens[0].parameters.findIndex(param => param.parameter_id == parameterId);
    if (index === -1) {
        state.form[0].screens[0].parameters.push({
            parameter_id: parameterId,
            parameter_name: parameterName,
            components: []
        });
    } else {
        state.form[0].screens[0].parameters[index] = {
            ...state.form[0].screens[0].parameters[index],
            parameter_id: parameterId,
            parameter_name: parameterName
        };
    }

    return state;
};
export const formSlice = createSlice({
    name: 'formHandler',
    initialState,
    reducers: {
        updateBucketField: (state, action) => {
            state.files = {};
            const { bucketName, bucketId } = action.payload;
            state.form[0].bucket_name = bucketName;
            state.form[0].bucket_id = bucketId;
            state.form[0].screens = [];
        },
        updateScreenField: (state, action) => {
            state.files = {};
            const { screenId, screenName } = action.payload;
            if (!state.form[0].screens.length) {
                state.form[0].screens[0] = {
                    screen_id: screenId,
                    screen_name: screenName,
                    parameters: []
                };
            } else {
                state.form[0].screens[0].screen_id = screenId;
                state.form[0].screens[0].screen_name = screenName;
                state.form[0].screens[0].parameters = [];
            }
        },
        updateComponentFields: (state, action) => {
            const { parameterId, parameterName, componentId, optionKey, optionId, ...rest } = action.payload;
            state = updateParameterFields(state, {
                payload: {
                    parameterId,
                    parameterName
                }
            });

            const paramIndex = state.form[0].screens[0].parameters.findIndex(
                param => param.parameter_id == parameterId
            );
            const componentIndex = state.form[0].screens[0].parameters[paramIndex].components.findIndex(
                comp => comp.id == componentId
            );
            if (componentIndex === -1) {
                state.form[0].screens[0].parameters[paramIndex].components.push({
                    id: componentId,
                    option_key: optionKey,
                    options_id: optionId,
                    ...rest
                });
            } else {
                state.form[0].screens[0].parameters[paramIndex].components[componentIndex] = {
                    ...state.form[0].screens[0].parameters[paramIndex].components[componentIndex],
                    option_key: optionKey,
                    options_id: optionId,
                    ...rest
                };
            }
        },
        updateDependentField: (state, action) => {
            const {
                field,
                values = state.dependentField?.[action.payload.field]?.values || [],
                selected = state.dependentField?.[action.payload.field]?.selected || ''
            } = action.payload;
            state.dependentField[field] = {
                selected,
                values
            };
        },
        updateFiles: (state, action) => {
            state.files[action.payload.name] = [action.payload.file];
        },
        resetFiles: (state, action) => {
            state.files[action.payload.name] = [];
        }
    },
    extraReducers: builder => {
        builder.addCase(sendScreenData.pending, state => {
            state.loading = true;
        });
        builder.addCase(sendScreenData.fulfilled, (state, action) => {
            (state.loading = false),
                (state.otpSended = action.payload.data.code === 'success' ? true : false),
                (state.error = action.payload.data.errors || '');
        });
        builder.addCase(sendScreenData.rejected, (state, action) => {
            (state.loading = false), (state.otpSended = false), (state.error = action.error.message);
        });
    }
});
export const {
    updateBucketField,
    updateScreenField,
    updateComponentFields,
    updateDependentField,
    updateFiles,
    resetFiles
} = formSlice.actions;
