const WhyEcoTab = () => {
    return (
        <ul className="nav nav-tabs m-auto mb-4 d-table border-0 eco-custom-tabs" id="whyEcoTab" role="tablist">
            <li className="nav-item d-table-cell" role="presentation">
                <button className="nav-link text-capitalize cp-medium fs-5 lh-1 active" id="for-buyer-tab" data-bs-toggle="tab" data-bs-target="#for-buyer" type="button" role="tab" aria-controls="for-buyer" aria-selected="true">for buyer</button>
            </li>
            <li className="nav-item d-table-cell" role="presentation">
                <button className="nav-link text-capitalize cp-medium fs-5 lh-1" id="for-technician-tab" data-bs-toggle="tab" data-bs-target="#for-technician" type="button" role="tab" aria-controls="for-technician" aria-selected="false">for technician</button>
            </li>
        </ul>)
        ;
}
export default WhyEcoTab;